import { FC } from "react";
import { AudioPlayerPropsI } from "./wavesurfer-audio-player";

import { clsxMerge } from "shared/lib/helpers";

const DefaultAudioPlayer: FC<
  AudioPlayerPropsI & { disableCacheBreaker?: boolean }
> = ({ url, className }) => {
  return (
    <audio controls className={clsxMerge("w-full", className)}>
      <source src={url} />
    </audio>
  );
};

export default DefaultAudioPlayer;
