import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";

interface AccountDispositionStatusPillPropsI {
  style?: string;
  label: string;
}

export const AccountDispositionStatusPill: FC<
  AccountDispositionStatusPillPropsI
> = ({ style, label }) => {
  if (!label) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "w-max min-w-[182px] px-2 py-1.5",
        "rounded text-center text-white typography-body-3-black",
        style
      )}
    >
      {label}
    </div>
  );
};

export const AccountDispositionStatusSmallPill: FC<
  AccountDispositionStatusPillPropsI
> = ({ style, label }) => {
  if (!label) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "w-max min-w-[84px] px-2 py-0",
        "rounded text-center text-white typography-header-8-semibold",
        style
      )}
    >
      {label}
    </div>
  );
};
