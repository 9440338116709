import { FC, useEffect, useMemo, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

import { clsxMerge, formatNumbers } from "shared/lib/helpers";
import { createKeyValuePair } from "shared/lib/helpers/utils";
import { AudioPlayer } from "shared/ui";

import { CampaignRecommendationCardI } from "./interface";

export const BackCard: FC<CampaignRecommendationCardI> = ({
  isSelected,
  recommendation_reason,
  eligible_lead_count,
  weekly_active_caller_count,
  booked_meetings_count,
  qualified_meetings_count,
  is_weekend_eligible,
  successful_pitch,

  isFlipped,
  onFlip,
}) => {
  const [isVisible, setIsVisibe] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisibe(true);
    }, 300);
  }, []);

  const stats = useMemo(
    () => [
      createKeyValuePair("Eligible Leads", formatNumbers(eligible_lead_count)),
      createKeyValuePair(
        "Booked Meetings",
        formatNumbers(booked_meetings_count)
      ),
      createKeyValuePair(
        "Qualified Meetings",
        formatNumbers(qualified_meetings_count)
      ),
      createKeyValuePair(
        "No Calling  Allowed",
        is_weekend_eligible ? "-" : "Sat, Sun"
      ),
    ],
    [
      eligible_lead_count,
      weekly_active_caller_count,
      booked_meetings_count,
      qualified_meetings_count,
      is_weekend_eligible,
    ]
  );

  return (
    <div
      className={clsxMerge(
        "h-full p-0",
        "transition-all duration-300",
        "btn-free",
        "flex flex-col justify-start",
        {
          "opacity-100": isVisible,
          "opacity-0": !isVisible || isFlipped,
          "!bg-[#E8F3FF]": isSelected,
          "shadow-[0_0_0_3px_#4474E3]": isSelected,
          "shadow-[0_0_0_0.5px_#C2C2C2]": !isSelected,
        }
      )}
    >
      <div className="min-h-[366px] w-full">
        <div
          className={clsxMerge(
            "w-full p-6",
            "items-start",
            "transition-all duration-300",
            {
              "!bg-[#E8F3FF]": isSelected,
            }
          )}
        >
          <h6 className="brand-typography-h5 mb-3">Campaign Details</h6>

          <div className="mb-4">
            {stats?.map(({ key, value }, i) => (
              <dl
                key={i}
                className={clsxMerge(
                  "flex items-center justify-between",
                  "brand-typography-body3 mb-2"
                )}
              >
                <dt className="font-normal">{key}</dt>
                <dd className="font-light">{value}</dd>
              </dl>
            ))}
          </div>
        </div>

        <div className="mb-10 min-h-[78px] w-full px-6">
          {!!successful_pitch?.recording_url && (
            <>
              <h6 className="brand-typography-h6 mb-2">
                Successful Pitch Example
              </h6>

              <AudioPlayer url={successful_pitch?.recording_url} />
            </>
          )}
        </div>
      </div>

      <button
        className="mb-4 flex w-full items-center justify-center"
        onClick={onFlip}
      >
        <ArrowPathIcon className="mr-1 w-5 text-[#4474E3]" />
        <span className="brand-typography-h7 text-[#4474E3]">View Payout</span>
      </button>

      <div
        className={clsxMerge(
          "border-t-[0.5px] p-6",
          "transition-all duration-300",
          "relative left-[-1px] top-[1px] w-[calc(100%+2px)]",
          "rounded-b-lg",
          "grow",
          {
            "!bg-[#4474E3] text-white": isSelected,
          }
        )}
      >
        <h6 className="ae-typography-body1 mb-2 font-semibold">
          Recommended For You
        </h6>
        <p className="brand-typography-body4">
          {recommendation_reason
            ? recommendation_reason
            : "Selected by the Glencoco team with love!"}
        </p>
      </div>
    </div>
  );
};
