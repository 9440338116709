import React, { FC } from "react";

import { HistoryCardTextInfoItem } from "shared/ui/activity-log/cards-v2/base";
import { getImageAssetPath } from "customer/lib/helpers/asset";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface HistoryCardAEInfoPropsI extends PropsWithClassNameI {
  isGlenXCampaign?: boolean;
  aeName: string;
}

export const HistoryCardAEInfo: FC<HistoryCardAEInfoPropsI> = ({
  isGlenXCampaign,
  aeName,
}) => (
  <HistoryCardTextInfoItem
    icon={
      <div className="flex w-6 justify-center">
        <img
          alt="Prospect Name & Title"
          src={getImageAssetPath("call-learnings/card/prospect.webp")}
          className="w-[18px]"
        />
      </div>
    }
    subtitle={isGlenXCampaign ? "Meeting Taker" : "AE Name"}
    info={[
      {
        title: aeName,
      },
    ]}
  />
);
