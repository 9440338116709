import { AccountDetailsStatusI } from "../interfaces/account";
import { ACCOUNT_DISPOSITION_STATUSES } from "../constants/account";
import {
  ACCOUNT_DISPOSITION_STATUS_PILL_PARAMS,
  ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS,
  ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS_GLENX,
} from "../constants/ui";

export const getAccountDispositionStatusParams = (
  accountStatus: AccountDetailsStatusI | undefined,
  isGlenXCampaign = false
) => {
  if (!accountStatus || accountStatus?.status === undefined) {
    return {
      label: "",
      style: "",
    };
  }

  if (accountStatus.status === "") {
    return ACCOUNT_DISPOSITION_STATUS_PILL_PARAMS[
      ACCOUNT_DISPOSITION_STATUSES.COLD_LEAD
    ];
  }

  if (
    accountStatus.status === ACCOUNT_DISPOSITION_STATUSES.FEEDBACK_RECEIVED &&
    accountStatus.meeting_status
  ) {
    const labelContainer = isGlenXCampaign
      ? ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS_GLENX
      : ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS;

    return labelContainer[accountStatus.meeting_status];
  }

  return ACCOUNT_DISPOSITION_STATUS_PILL_PARAMS[accountStatus.status];
};
