import { FC, useState } from "react";
import axios from "axios";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { useEffectOnceWhen } from "shared/lib/hooks";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";
import CollapsibleText from "shared/ui/collapsible-text";

interface HistoryCardCallSummaryPropsI extends PropsWithClassNameI {
  summaryURL: string;
}

/**
 * Call summary is stored as a .txt file on S3, so we need to fetch and parse it
 * before displaying.
 */
const useFetchCallSummary = (summaryURL: string | undefined) => {
  const [isLoading, setIsLoading] = useState(!!summaryURL);
  const [summary, setSummary] = useState<string>();

  useEffectOnceWhen(async () => {
    if (!summaryURL) {
      return;
    }

    const callSummaryResponse = await axios(summaryURL);

    if (callSummaryResponse.status !== 200) {
      setIsLoading(false);
      return;
    }

    console.debug("SETTING CALL SUMMARY", callSummaryResponse.data);
    setSummary(callSummaryResponse.data);
    setIsLoading(false);
  }, summaryURL);

  return {
    isLoading,
    summary,
  };
};

export const HistoryCardCallSummary: FC<HistoryCardCallSummaryPropsI> = ({
  className,
  summaryURL,
}) => {
  const { isLoading, summary } = useFetchCallSummary(summaryURL);

  if (!summaryURL || isLoading || !summary) {
    return null;
  }

  return (
    <div className={clsxMerge("flex flex-col gap-1", className)}>
      <h4 className="typography-body-4-bold gradient-text">Call Summary</h4>

      <div className="typography-body-4 flex items-center gap-1.5 text-black">
        {isLoading ? (
          <>
            <span>Loading call summary</span>
            <LoadingSpinner />
          </>
        ) : (
          <CollapsibleText visibleLines={4} buttonClassName="text-black/80">
            {summary ?? "No call summary available"}
          </CollapsibleText>
        )}
      </div>
    </div>
  );
};
