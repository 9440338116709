import {
  FC,
  Fragment,
  MouseEventHandler,
  ReactNode,
  useMemo,
  useRef,
} from "react";

import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

import { ChevronDownIcon } from "../icons";
import { clsxMerge } from "shared/lib/helpers";

interface ButtonMenuPropsI {
  id?: string;
  btnIcon?: ReactNode;
  text?: string;
  items: ReactNode[];
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  verticalPosition?: "top" | "bottom";
  position?: "start" | "end";
  isArrowVisible?: boolean;
  renderTrigger?: () => ReactNode;
  isHoverMode?: boolean;
  menuItemClassName?: string;
}

const triggerContainerPreventClickHandler: MouseEventHandler<
  HTMLLabelElement
> = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const ButtonMenu: FC<ButtonMenuPropsI> = ({
  id,
  btnIcon,
  text,
  items,
  className,
  containerClassName,
  contentClassName,
  isArrowVisible = true,
  verticalPosition = "bottom",
  position = "end",
  renderTrigger,
  isHoverMode,
  menuItemClassName,
}) => {
  const triggerRef = useRef<HTMLLabelElement>(null);
  const triggerHoverProps = useMemo(
    () =>
      isHoverMode
        ? {
            onMouseEnter: () => triggerRef.current?.focus(),

            onClick: triggerContainerPreventClickHandler,
          }
        : {},
    [isHoverMode]
  );

  const menuRef = useRef<HTMLUListElement>(null);
  const menuHoverProps = useMemo(
    () =>
      isHoverMode
        ? {
            onMouseLeave: () => triggerRef.current?.blur(),
          }
        : {},
    [isHoverMode]
  );

  return (
    <div
      className={clsxMerge(
        "dropdown",
        {
          "dropdown-bottom": !verticalPosition || verticalPosition === "bottom",
          "dropdown-top": verticalPosition === "top",
          "dropdown-end": !position || position === "end",
          "dropdown-start": position === "start",
        },
        containerClassName
      )}
    >
      <label
        id={id}
        ref={triggerRef}
        tabIndex={0}
        className={clsxMerge("dropdown min-w-[40px] p-3", className)}
        {...triggerHoverProps}
      >
        {renderTrigger ? (
          renderTrigger()
        ) : (
          <Fragment>
            {text ? (
              <div className="flex items-center gap-2">
                {btnIcon}
                {text} {isArrowVisible && <ChevronDownIcon className="h-2" />}
              </div>
            ) : (
              btnIcon || (
                <EllipsisVerticalIcon className="relative -top-1 h-6" />
              )
            )}
          </Fragment>
        )}
      </label>

      <ul
        ref={menuRef}
        tabIndex={0}
        className={clsxMerge(
          "menu-sm dropdown-content menu mt-2 flex-nowrap rounded-lg shadow-xl",
          "z-[30] w-52",
          "bg-base-100 shadow",
          "ae-typography-body2",
          "max-h-[40vh] overflow-hidden overflow-y-auto",
          contentClassName
        )}
        {...menuHoverProps}
      >
        {items.filter(Boolean).map((item, i) => (
          <li
            className={clsxMerge(
              "[&:active>*]:active:bg-black/20 [&:active>*]:active:text-black",
              menuItemClassName
            )}
            key={i}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ButtonMenu;
