export const STATUS_TYPES = {
  UPCOMING: "upcoming",
  COMPLETED: "completed",
  OVERDUE: "overdue",
  PENDING: "pending",
  PENDING_FEEDBACK: "pending_feedback", //PENDING_FEEDBACK is the same as PENDING, but we have both because backend didn't maintain consistency and said they can't change it
  NO_SHOW: "no_show",
  RESCHEDULED: "rescheduled",
  QUALIFIED: "qualified",
  UNQUALIFIED: "unqualified",
  ALERT: "alert",
  CANCELLED: "cancelled",
  MORE_MATERIALS: "more_materials",
  MATERIALS_SENT: "materials_sent",
} as const;

export const STATUS_TYPES_STRING_MAP = {
  [STATUS_TYPES.UPCOMING]: "Upcoming",
  [STATUS_TYPES.COMPLETED]: "Completed",
  [STATUS_TYPES.OVERDUE]: "Overdue",
  [STATUS_TYPES.PENDING]: "Pending feedback",
  [STATUS_TYPES.PENDING_FEEDBACK]: "Pending feedback",
  [STATUS_TYPES.NO_SHOW]: "No show",
  [STATUS_TYPES.RESCHEDULED]: "Rescheduled",
  [STATUS_TYPES.QUALIFIED]: "Qualified",
  [STATUS_TYPES.UNQUALIFIED]: "Unqualified",
  [STATUS_TYPES.ALERT]: "Alert",
  [STATUS_TYPES.CANCELLED]: "Cancelled",
  [STATUS_TYPES.MORE_MATERIALS]: "Requested materials",
  [STATUS_TYPES.MATERIALS_SENT]: "Materials sent",
} as const;

export const STATUS_TYPES_STRING_MAP_GLENX = {
  ...STATUS_TYPES_STRING_MAP,
  [STATUS_TYPES.QUALIFIED]: "Opportunity Created",
  [STATUS_TYPES.UNQUALIFIED]: "Opportunity Not Created",
};
