import { FC } from "react";
import { PhoneIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardCallRecording } from "shared/ui/activity-log/cards-v2/base/call-recording";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import { HistoryCardCallbackBookedInfo } from "shared/ui/activity-log/cards-v2/base/event-date";
import { HistoryCardCallSummary } from "./base/call-summary";

const ACTIVITY_TYPE_CONFIG = {
  icon: PhoneIcon,
  title: "Saved for a callback",
};

const HistoryCardCallbackBookedV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
  isGlenXCampaign,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />
      <HistoryCardCallbackBookedInfo date={activity.follow_up_time} />
      <HistoryCardContactInfo contact={activity.contact} />
      <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
    </div>

    <div className="flex flex-col gap-4">
      {activity.call_recording_url?.recording_url && (
        <HistoryCardCallRecording
          recordingURL={activity.call_recording_url?.recording_url}
          transcriptURL={activity.call_transcript_url}
          hadDiscussion={!!activity.call_has_discussion}
        />
      )}

      {!!activity.call_summary_url && (
        <HistoryCardCallSummary summaryURL={activity.call_summary_url} />
      )}

      <HistoryCardCallNotes
        notes={activity.notes}
        isGlenXCampaign={isGlenXCampaign}
      />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardCallbackBookedV2;
