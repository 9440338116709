import axios, { AxiosInstance } from "axios";

import { TRAINING_MODULE_TYPES } from "shared/lib/constants/training";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { DefaultResponseI } from "@/api/glencoco";

export interface GetTrainingModulesResponseI extends DefaultResponseI {
  training_modules: Array<TrainingModuleI>;
}

export interface GetTrainingModuleResponseI extends DefaultResponseI {
  training_module: TrainingModuleI;
  training_module_presigned_url?: string;
  training_module_ai_generated_markdown_url?: string;
}

export interface GetTrainingModuleUploadURLResponseI {
  markdown_presigned_upload_url: string;
  training_module_presigned_upload_url: string;
}

export interface TrainingModuleI {
  id: string;
  title?: string;
  sub_title?: string;
  is_hidden?: boolean;
  is_custom_module?: boolean;
  has_unpublished_changes?: boolean;
  has_unpublished_training_module_changes?: boolean;
  training_module_type?: TrainingModuleTypeI;
  user_is_started?: boolean;
  user_is_completed?: boolean;
  icon_url?: string;
}

export type TrainingModuleTypeI = ValueOfObjectFields<
  typeof TRAINING_MODULE_TYPES
>;

export const TrainingAPIEndpoints = (api: AxiosInstance) => ({
  getTrainingModules: (campaignId: string) =>
    api.get<GetTrainingModulesResponseI>(
      `/v1/campaign/${campaignId}/training_modules`
    ),

  getTrainingModule: (campaignId: string, trainingModuleId: string) =>
    api.get<GetTrainingModuleResponseI>(
      `/v1/campaign/${campaignId}/training_module/${trainingModuleId}`
    ),

  submitTrainingModuleCompleted: (
    campaignId: string,
    trainingModuleId: string
  ) =>
    api.post(
      `v1/campaign/${campaignId}/training_module/${trainingModuleId}/complete`
    ),

  enrollIntoCampaignTraining: (campaignId: string) =>
    api.post(`/v1/campaign/${campaignId}/enroll`, {}),

  submitTrainingCompleted: (campaignId: string) =>
    api.post(`/v1/campaign/${campaignId}/training/complete`, {}),

  getAITrainingModuleUploadURL: (
    campaignId: string,
    trainingModuleId: string
  ) =>
    api.post<GetTrainingModuleUploadURLResponseI>(
      `/v1/campaign/${campaignId}/training_modules/${trainingModuleId}/submit_new_block_notes_url`
    ),

  uploadTrainingModuleContent: (preSignedUploadURL: string, document: Blob) =>
    axios.put(preSignedUploadURL, document, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
});
