import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { checkIfClient } from "shared/lib/helpers";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";

const DIALER_PAGES = ["/session", "/list", "/accounts"];

export const LOG_TYPES = {
  debug: "debug",
  info: "info",
  warn: "warn",
  error: "error",
} as const;

const isProduction = process.env.NEXT_PUBLIC_ENV === "production";

const isLocal = !["production", "staging"].includes(
  process.env.NEXT_PUBLIC_ENV as string
);

(() => {
  const isClient = checkIfClient();

  if (isClient) {
    const isDialerPages = DIALER_PAGES.some((path) =>
      window.location.href?.includes(path)
    );

    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
      site: "datadoghq.com",
      forwardErrorsToLogs: isProduction,
      forwardConsoleLogs:
        isProduction && isDialerPages
          ? "all"
          : isProduction
          ? [LOG_TYPES.error]
          : undefined,
      sessionSampleRate: 100,

      beforeSend: (log) => {
        // discard aborted requests

        if (log.http && log.http.status_code === 0) {
          return false;
        }

        return true;
      },
    });
  }
})();

datadogLogs.setGlobalContext({
  env: process.env.NEXT_PUBLIC_ENV,
  service: `frontend-${process.env.NEXT_PUBLIC_ENV}-user.glencoco.com`,
});

if (!isProduction) {
  datadogLogs.logger.setHandler("console");
}

// DOCUMENTATION
// https://app.datadoghq.com/logs/onboarding/client
// https://docs.datadoghq.com/logs/log_collection/javascript/
// logger.debug | info | warn | error (message: string, messageContext = Context)

export const defaultMessageContext = () => ({
  level: 1,
});

interface DDLogI {
  log: (
    message?: string,
    messageContext?: object,
    type?: keyof typeof LOG_TYPES
  ) => void;
  debug: (message?: string, messageContext?: object) => void;
  warn: (message?: string, messageContext?: object) => void;
  error: (message?: string, messageContext?: object) => void;
  rum: {
    log: (
      message?: string,
      messageContext?: object,
      type?: keyof typeof LOG_TYPES
    ) => void;
    error: (message?: string, messageContext?: object) => void;
  };
}
// NOTE works clientside ONLY
export const dd: DDLogI = {
  log: (
    message?: string,
    messageContext?: object,
    type?: keyof typeof LOG_TYPES
  ) => {
    if (checkIfClient()) {
      if (isLocal) {
        console.log(message, messageContext);
        return;
      }

      if (!type || !Object.keys(LOG_TYPES).includes(type)) {
        datadogLogs.logger.info(message || "", {
          ...defaultMessageContext(),
          ...(messageContext || {}),
        });
      } else {
        datadogLogs.logger[type](message || "", {
          ...defaultMessageContext(),
          ...(messageContext || {}),
        });
      }
    }
  },

  debug: (message?: string, messageContext?: object) => {
    dd.log(message, messageContext, LOG_TYPES.debug as keyof typeof LOG_TYPES);
  },

  warn: (message?: string, messageContext?: object) => {
    dd.log(message, messageContext, LOG_TYPES.warn as keyof typeof LOG_TYPES);
  },

  error: (message?: string, messageContext?: object) => {
    dd.log(message, messageContext, LOG_TYPES.error as keyof typeof LOG_TYPES);
  },

  rum: {
    log: (...args) => {
      dd.log(...args);
      datadogRum.addAction(args[0] as string, args[1]);
    },

    error: (message, messageContext) => {
      dd.error(message, messageContext);
      datadogRum.addError(message, messageContext);
    },
  },
};

export const useDatadogRum = () =>
  useEffectOnce(() => {
    if (isProduction) {
      console.log("Initialization Datadog RUM");

      datadogRum.init({
        applicationId: process.env
          .NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID as string,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN as string,
        site: "datadoghq.com",
        service: "glencoco-user-platform",
        env: process.env.NEXT_PUBLIC_ENV,
        version: "6.0.2",
        sessionSampleRate: 100,
        sessionReplaySampleRate: parseInt(
          process.env
            .NEXT_PUBLIC_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE as string,
          10
        ),
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: "allow",
      });

      datadogRum.startSessionReplayRecording();
    }
  });
