import { FC, useState } from "react";

import Sidebar from "shared/ui/sidebar";
import CallRecordingTranscript from "shared/ui/call-recording-transcript";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface HistoryCardCallRecordingPropsI extends PropsWithClassNameI {
  recordingURL?: string;
  transcriptURL?: string;
  hadDiscussion: boolean;
}

export const HistoryCardCallRecording: FC<HistoryCardCallRecordingPropsI> = ({
  className,
  recordingURL,
  transcriptURL,
  hadDiscussion,
}) => {
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(false);

  return (
    <>
      <div
        className={clsxMerge(
          "@xl:flex hidden w-full items-center gap-2",
          className
        )}
      >
        <audio src={recordingURL} className="w-full" controls />

        <div
          className={clsxMerge(
            "flex flex-col justify-center gap-2",
            transcriptURL ? "w-[148px]" : "hidden"
          )}
        >
          {!!transcriptURL && (
            <button
              className="ae-link b-typography-body1"
              onClick={() => setIsTranscriptVisible(true)}
            >
              View Transcript
            </button>
          )}

          {hadDiscussion && (
            <span className="b-typography-body1 rounded bg-[#F88167] px-2 py-1 text-center text-white">
              Had Discussion
            </span>
          )}
        </div>
      </div>

      {isTranscriptVisible && transcriptURL && (
        <Sidebar
          isFullPage={false}
          containerClassName="bg-white w-[600px]"
          backgroundBlurClassName="hidden"
          className="h-full overflow-y-hidden py-0"
          title="Transcript"
          handleClose={() => setIsTranscriptVisible(false)}
        >
          <CallRecordingTranscript
            className="h-full"
            titleClassName="hidden"
            transcriptClassName="max-h-[calc(100%-85px)]"
            transcriptURL={transcriptURL}
          />
        </Sidebar>
      )}
    </>
  );
};
