import clsx from "clsx";
import { FC } from "react";
import {
  STATUS_TYPES,
  STATUS_TYPES_STRING_MAP,
  STATUS_TYPES_STRING_MAP_GLENX,
} from "shared/lib/constants/statuses";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { PropsWithClassNameI } from "../../../lib/interfaces/ui";

export const STATUS_PILL_CSS_PROPERTIES = {
  [STATUS_TYPES.UPCOMING]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.COMPLETED]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.OVERDUE]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.PENDING]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.PENDING_FEEDBACK]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.NO_SHOW]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [STATUS_TYPES.RESCHEDULED]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.QUALIFIED]: {
    color: "text-black/80",
    bg: "bg-[#DFF4B4]",
  },
  [STATUS_TYPES.UNQUALIFIED as string]: {
    color: "text-black/80",
    bg: "bg-[#FFEFEB]",
  },
  [STATUS_TYPES.ALERT]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [STATUS_TYPES.CANCELLED]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.MORE_MATERIALS]: {
    color: "text-[white]",
    bg: "bg-black",
  },
  [STATUS_TYPES.MATERIALS_SENT]: {
    color: "text-[white]",
    bg: "bg-black",
  },
} as const;

interface StatusPillI extends PropsWithClassNameI {
  status: ValueOfObjectFields<typeof STATUS_TYPES>;
  isGlenXCampaign?: boolean;
}

const StatusPill: FC<StatusPillI> = ({
  status,
  className,
  isGlenXCampaign,
}) => {
  const labelContainer = isGlenXCampaign
    ? STATUS_TYPES_STRING_MAP_GLENX
    : STATUS_TYPES_STRING_MAP;

  return (
    <div
      className={clsx(
        "ae-typography-detail1 w-fit rounded px-2 py-1 text-center",
        STATUS_PILL_CSS_PROPERTIES[status]?.bg,
        STATUS_PILL_CSS_PROPERTIES[status]?.color,
        className
      )}
    >
      {labelContainer[status]}
    </div>
  );
};

export default StatusPill;
