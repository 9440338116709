import { useState } from "react";
import dayjs from "dayjs";

import { useInterval } from "./use-interval";

type UseTimeAgoI = (createdAt: string, updateInterval?: number) => string;

const DEFAULT_UPDATE_INTERVAL = 5000;

export const useTimeAgo: UseTimeAgoI = (
  createdAt,
  updateInterval = DEFAULT_UPDATE_INTERVAL
) => {
  const [timeAgo, setTimeAgo] = useState(dayjs(createdAt).fromNow());

  useInterval(
    async () => {
      setTimeAgo(dayjs(createdAt).fromNow());
    },
    DEFAULT_UPDATE_INTERVAL,
    [createdAt, updateInterval]
  );

  return timeAgo;
};
