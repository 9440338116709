import { FC } from "react";

import { AccountDetailsLayout as Layout } from "./layout";

// import { SidebarExtentionResources as Resources } from "./sidebar/components/resources";

import { ConnectedLeadSection } from "./primary-content/sections/connected-lead-section";
import { AccountSection } from "./sidebar/sections/account-section";
import { AccountTopSection } from "./primary-content/sections/account-top-section";
import { StatusSection } from "./sidebar/sections/status-section";
import { ListsSection } from "./sidebar/sections/lists-section";
import { LeadsSection } from "./sidebar/sections/leads-section";

import { PrimaryContent } from "./primary-content";

import { AccountDetailsControlMenu as ControlMenu } from "./control-menu";
import { AccountDetailsProvider, AccountDetailsProviderI } from "./context";

import { AccountDetailsModals as ModalsAndSidebars } from "@/modules/pipeline/account-details/modals";
import { AccountsContextProvider } from "@/context/accounts";
import { AddUserToListSidebarProvider } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { EmailTemplatesSidebarProvider } from "@/modules/email-templates-sidebar/context";

export const AccountDetailsModule: FC<AccountDetailsProviderI> = (props) => (
  <AccountsContextProvider>
    <AccountDetailsProvider {...props}>
      <AddUserToListSidebarProvider>
        <EmailTemplatesSidebarProvider>
          <Layout>
            <Layout.SidebarExtention>
              {/* <Resources /> */}
            </Layout.SidebarExtention>

            <Layout.GridRows>
              <Layout.Top>
                <AccountTopSection />
                <ConnectedLeadSection />
              </Layout.Top>

              <Layout.GridColumns>
                <Layout.Sidebar>
                  <div className="flex flex-col divide-y pb-36">
                    <AccountSection />
                    <StatusSection />
                    <ListsSection />
                    <LeadsSection />
                  </div>
                </Layout.Sidebar>

                <Layout.Content>
                  <PrimaryContent />
                </Layout.Content>
              </Layout.GridColumns>
            </Layout.GridRows>

            <ControlMenu />

            <ModalsAndSidebars />
          </Layout>
        </EmailTemplatesSidebarProvider>
      </AddUserToListSidebarProvider>
    </AccountDetailsProvider>
  </AccountsContextProvider>
);
export { DISQUALIFY_DISPOSITION_LABELS } from "shared/lib/constants/dispositions";
