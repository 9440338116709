import { FC } from "react";
import { InboxStackIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";

const ACTIVITY_TYPE_CONFIG = {
  icon: InboxStackIcon,
  title: "Archive",
};

const HistoryCardArchiveV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
  isGlenXCampaign,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />
      <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
    </div>

    <div className="flex flex-col gap-4">
      <HistoryCardCallNotes
        notes={activity.notes}
        isGlenXCampaign={isGlenXCampaign}
      />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardArchiveV2;
