import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface LoadingSpinnerPropsI extends PropsWithClassNameI {
  spinnerClassName?: string;
}

export const LoadingSpinner: FC<LoadingSpinnerPropsI> = ({
  className,
  spinnerClassName,
}) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clsxMerge("animate-spin", spinnerClassName)}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  </div>
);
