import { useEffect } from "react";
import { TIME_INTERVALS_MS } from "../constants/time";

type UseIntervalI = (
  asyncCallback: (intervalId?: number, ...rest: unknown[]) => unknown,
  intervalMs: number,
  dependenciesArray?: Array<any>
) => void;

export const useInterval: UseIntervalI = (
  asyncCallback,
  intervalMs = TIME_INTERVALS_MS.ONE_SECOND,
  dependenciesArray = []
) => {
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      void asyncCallback(intervalId);
    }, intervalMs);

    return () => {
      window.clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesArray);
};
