import { FC } from "react";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardAEInfo } from "shared/ui/activity-log/cards-v2/base/ae-info";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

const ACTIVITY_TYPE_CONFIG = {
  icon: EnvelopeIcon,
  title: "Customer marked email with materials sent",
};

const HistoryCardMarkTentativeInterestDoneV2: FC<
  AccountHistoryCardBasePropsI
> = ({ activity, isGlenXCampaign }) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />
      <HistoryCardAEInfo
        aeName={activity.ae_name || "N/A"}
        isGlenXCampaign={isGlenXCampaign}
      />
    </div>

    <div className="flex flex-col gap-4">
      <HistoryCardCallNotes
        isCustomerNotes
        isGlenXCampaign={isGlenXCampaign}
        notes={activity.notes}
      />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardMarkTentativeInterestDoneV2;
