import { HTMLProps, ReactNode } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface CheckboxI extends PropsWithClassNameI {
  name: string;
  value?: boolean;
  id?: string;
  label?: ReactNode;
  labelClassName?: string;
  labelTextClassName?: string;
  isToggleCheckBox?: boolean;
  disabled?: boolean;
  inputProps?: HTMLProps<HTMLInputElement>;
}

const DefaultCheckbox = (props: any) => <input type="checkbox" {...props} />;

const Checkbox =
  (Field = DefaultCheckbox) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    value,
    label,
    id,
    className,
    labelClassName,
    labelTextClassName,
    isToggleCheckBox,
    disabled,
    inputProps = {},
  }: CheckboxI) =>
    (
      <div className={clsxMerge("form-control ", className)}>
        <label
          className={clsxMerge(
            "label justify-start",
            disabled ? "cursor-disabled" : "cursor-pointer",
            labelClassName
          )}
        >
          <Field
            type="checkbox"
            name={name}
            id={id}
            value={isToggleCheckBox ? undefined : value}
            className={clsxMerge(
              "checkbox h-4 w-4 rounded-sm checked:border-[#014DFE]",
              "checked:bg-[linear-gradient(-45deg,transparent_65%,#014DFE_65.99%),linear-gradient(45deg,transparent_75%,#014DFE_75.99%),_linear-gradient(-45deg,#014DFE_40%,transparent_40.99%),linear-gradient(45deg,#014DFE_30%,hsl(var(--chkfg))_30.99%,hsl(var(--chkfg))_40%,transparent_40.99%),linear-gradient(-45deg,hsl(var(--chkfg))_50%,#014DFE_50.99%)]",
              "disabled:cursor-disabled disabled:border-[1px]",
              "disabled:border-black/80 disabled:bg-black/30",
              {
                "disabled:checked:bg-[linear-gradient(-45deg,transparent_65%,#c4c4c4_65.99%),linear-gradient(45deg,transparent_75%,#c4c4c4_75.99%),_linear-gradient(-45deg,#c4c4c4_40%,transparent_40.99%),linear-gradient(45deg,#c4c4c4_30%,#000000_30.99%,#000000_40%,transparent_40.99%),linear-gradient(-45deg,#000000_50%,#c4c4c4_50.99%)]":
                  disabled || inputProps.disabled,
              }
            )}
            disabled={disabled}
            {...inputProps}
          />

          <span
            className={clsxMerge(
              "label-text ml-4 text-left",
              labelTextClassName
            )}
          >
            {label}
          </span>
        </label>
      </div>
    );

export default Checkbox;
