import {
  ComponentProps,
  FC,
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import Link from "next/link";
import { twMerge } from "tailwind-merge";
import truncate from "lodash/truncate";
import clsx from "clsx";

import { CursorFollower } from "./cursor-follower";
import { Avatar } from "shared/ui";

import { PITCH_MAX_LENGTH, TRAINING_CARD_STATUSES } from "./constants";
import { TrainingCardStatusPill as StatusPill } from "./status-pill";
import { formatNumbers } from "../../../lib/helpers/numbers";
import { INDUSTRIES } from "../../../lib/constants/industry";

const NOT_ELIGIBLE_DESCRIPTION =
  "Unfortunately you are not eligible to make calls for this campaign. Account executives based their decision for approval on a variety of factors. While this campaign wasn’t an ideal fit, other campaigns may offer more opportunities for you.";

interface TrainingCardPropsI {
  name: string;
  industry?: string;
  percentComplete: number;
  statuses?: Array<
    | (typeof TRAINING_CARD_STATUSES)[keyof typeof TRAINING_CARD_STATUSES]
    | undefined
  >;
  isAssessmentInProgress?: boolean;
  logoURL?: string;
  className?: string;

  price?: number;
  elevator_pitch?: string;
  startCallingUrl?: string;
  assessmentUrl?: string;
  onStartTraining?: () => void;
  meta?: Array<{
    label: string;
    value: string | ReactElement;
    Icon?: FunctionComponent<ComponentProps<"svg">>;
    info?: string;
    href?: string;
  }>;
  // On Clicking the whole thing
  onSelect?: () => void;
  shouldShowPopup?: boolean;
  bottomSlot?: ReactElement;
  isDisabled?: boolean;
}

const TrainingCard: FC<TrainingCardPropsI> = ({
  name,
  industry,
  elevator_pitch,
  startCallingUrl,
  assessmentUrl,
  onStartTraining = () => {},
  statuses,
  isAssessmentInProgress,
  percentComplete,
  logoURL,
  className,
  price,
  meta,
  onSelect = () => {},
  shouldShowPopup = true,
  bottomSlot,
  isDisabled = false,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [isTrackingMouseMove, setIsTrackingMouseMove] = useState(false);
  const [isMouseOverLongEnough, setIsMouseOverLongEnough] = useState(false);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const setXY = (e: any) => {
    if (isTrackingMouseMove) {
      setX(e.clientX);
      setY(e.clientY);
    }
  };

  const onMouseEnter = () => {
    setIsTrackingMouseMove(true);
  };

  const onMouseLeave = () => {
    setIsPopupVisible(false);
    setIsTrackingMouseMove(false);
    setIsMouseOverLongEnough(false);
  };

  const isAssessmentAvailable = useMemo(
    () =>
      percentComplete >= 100 &&
      statuses?.some(
        (s) =>
          s === TRAINING_CARD_STATUSES.TRAINING_COMPLETED ||
          s === TRAINING_CARD_STATUSES.PROGRESS
      ),
    [percentComplete, statuses]
  );

  useEffect(() => {
    let interval: any;
    let passed = 0;
    const timeInterval = 50;
    const WHEN_SHOW_POPUP_DELAY = 300;

    if (isTrackingMouseMove) {
      interval = setInterval(() => {
        if (passed >= WHEN_SHOW_POPUP_DELAY) {
          setIsMouseOverLongEnough(true);
        }

        passed = passed + timeInterval;
      }, timeInterval);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isTrackingMouseMove]);

  useEffect(() => {
    if (isMouseOverLongEnough && shouldShowPopup) {
      setIsPopupVisible(true);
    }
  }, [isMouseOverLongEnough, shouldShowPopup]);

  return (
    <div
      className={twMerge(
        clsx(
          "card relative cursor-pointer bg-white/40",
          "rounded-lg border-[1px] border-black/10",
          "transition-colors duration-300",
          "group hover:border-white/60 hover:bg-white/60",
          "min-h-[222px]",
          "h-full",
          className
        )
      )}
      onMouseMove={setXY}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        if (!isDisabled) {
          onSelect();
        }
      }}
    >
      <div className="h-full">
        {!!percentComplete && (
          <div className="relative flex w-[calc(100%-12px)]">
            <div
              style={{ width: `${percentComplete}%` }}
              className={clsx(
                "absolute left-1 top-[-2px] h-[2px] rounded-full",
                "transition-all duration-300",
                {
                  "bg-[#014DFE]": percentComplete <= 99,
                  "bg-[#0E6927]": percentComplete === 100,
                }
              )}
            >
              {percentComplete !== 100 && (
                <div
                  className={clsx(
                    "absolute left-[calc(100%-5px)] top-[-11px]",
                    "h-6 w-6 rounded-full",
                    "ae-typography-detail3 text-white",
                    "flex items-center justify-center",
                    "transition-[opacity,transform] duration-300",
                    "scale-0 opacity-0 group-hover:scale-90 group-hover:opacity-100",
                    {
                      "bg-[#014DFE]": percentComplete <= 99,
                      "bg-[#0E6927]": percentComplete === 100,
                    }
                  )}
                >
                  {percentComplete}%
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex h-full flex-row justify-between p-6">
          <div
            className={clsx("flex w-full flex-col justify-between", {
              "opacity-50": isDisabled,
            })}
          >
            <div className="flex flex-col">
              <Avatar
                src={logoURL}
                alt="Company logo"
                placeholderText={name}
                className="mb-4 mr-4 h-[40px] w-[40px] rounded-full"
              />

              <div className="mb-3 flex flex-col justify-center">
                <h2 className="ae-typography-h4">{name}</h2>
              </div>

              <div className="mb-4">
                <p className="ae-typography-body2 text-base-content/60">
                  {truncate(elevator_pitch, { length: PITCH_MAX_LENGTH })}
                </p>
              </div>
            </div>

            <div className="ae-typography-body3 text-base-content/80 mt-1 flex flex-col">
              <div className="flex items-center justify-between gap-2">
                <div>
                  {industry && (
                    <span
                      className={clsx(
                        "relative mr-5",
                        "after:contnet-[''] after:bg-base-content after:absolute after:h-1 after:w-1 after:rounded-full after:text-xl",
                        "after:-right-3 after:top-[5px]"
                      )}
                    >
                      {INDUSTRIES.find(({ value }) => value === industry)
                        ?.label || industry}
                    </span>
                  )}
                  <span>${formatNumbers(price)}</span>
                </div>

                {statuses?.includes(TRAINING_CARD_STATUSES.APPROVED) && (
                  <Link
                    className="btn-nofill btn-ae-s"
                    href={startCallingUrl || ""}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Start Calling
                  </Link>
                )}

                {percentComplete > 0 && percentComplete < 100 && (
                  <button
                    className="btn-nofill btn-ae-s"
                    onClick={(e) => {
                      e.stopPropagation();
                      onStartTraining();
                    }}
                  >
                    Continue Training
                  </button>
                )}

                {isAssessmentAvailable && (
                  <Link
                    className="btn-nofill btn-ae-s"
                    href={assessmentUrl || ""}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {`${
                      isAssessmentInProgress ? "Continue" : "Start"
                    } Certification`}
                  </Link>
                )}

                {statuses?.includes(TRAINING_CARD_STATUSES.RETRY) && (
                  <Link
                    className="btn-nofill btn-ae-s"
                    href={assessmentUrl || ""}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Retry Certification
                  </Link>
                )}
              </div>

              {bottomSlot}
            </div>
          </div>

          <div className="absolute right-7">
            <StatusPill statuses={statuses || []} />
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="relative">
          <CursorFollower startX={x} startY={y}>
            <div className="p-6">
              <div className="mb-6">
                <Avatar
                  src={logoURL}
                  alt="Company logo"
                  placeholderText={name}
                  className="mr-4 h-[40px] w-[40px] rounded-full"
                />
              </div>
              <div className="mb-6">
                <h2 className="ae-typography-h2">{name}</h2>
              </div>

              {isDisabled ? (
                <div>
                  <p className="ae-typography-body3 mb-2 text-[#FF5E3A]">
                    You're Not Eligible For This Campaign
                  </p>
                  <p className="ae-typography-body2">
                    {NOT_ELIGIBLE_DESCRIPTION}
                  </p>
                </div>
              ) : (
                <div>
                  {meta?.map(({ Icon, ...m }, i) => (
                    <div
                      key={i}
                      className={clsx(
                        "text-base-content ae-typography-body2",
                        "flex items-center",
                        "mb-5"
                      )}
                    >
                      <div className="mr-3 flex h-10 w-10 items-center justify-center rounded-lg border border-black/10">
                        {Icon && <Icon className="w-4" />}
                      </div>
                      <div className="flex flex-col">
                        <label className="text-base-content/40 ae-typography-detail1 mb-1 font-normal">
                          {m.label}
                        </label>
                        <span className="ae-typography-body-3 font-bold">
                          {m.href ? <a href={m.href}>{m.value}</a> : m.value}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </CursorFollower>
        </div>
      )}
    </div>
  );
};

export default TrainingCard;
