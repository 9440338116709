import { FC, useEffect, useRef, useState, ChangeEvent } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { CheckmarkIcon, ChevronDownIcon } from "../../icons";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { useTimeout } from "shared/lib/hooks/use-timeout";

export interface CustomSelectFieldOptionI {
  value: string;
  label: string;
}

export interface SearchSelectFieldPropsI extends PropsWithClassNameI {
  options: Array<CustomSelectFieldOptionI>;
  selectedValue?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  triggerClassName?: string;
  isCaseInSensitive?: boolean;
}

interface DropdownContentPropsI extends PropsWithClassNameI {
  options: Array<CustomSelectFieldOptionI>;
  selected?: string;
  searchText?: string;
  handleSelect?: (opt: CustomSelectFieldOptionI) => void;
  handleSearchInput?: (ev: ChangeEvent<HTMLInputElement>) => void;
}

const DropdownContent: FC<DropdownContentPropsI> = ({
  options,
  selected = "",
  searchText = "",
  handleSelect = () => {},
  handleSearchInput = () => {},
  className = "",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useTimeout(() => {
    setIsVisible(true);
    inputRef.current?.focus();
  }, 100);

  return (
    <div
      className={clsxMerge(
        "flex max-h-[258px] w-max min-w-[100%] max-w-[200%] translate-y-[4px] flex-col",
        "overflow-y-auto rounded-lg bg-white shadow-[0px_4px_100px_rgba(0,0,0,0.1)]",
        "absolute z-[1]",
        "transition-[opacity,transform] duration-300",
        isVisible ? "scale-100 opacity-100" : "scale-[0.9] opacity-0",
        className
      )}
    >
      <div className="sticky top-0 bg-white">
        <div className="flex items-center px-4 py-3">
          <MagnifyingGlassIcon className="mr-3 h-5 text-black/40" />

          <input
            ref={inputRef}
            type="text"
            placeholder="Search"
            className="w-full border-none outline-none placeholder:text-black/40 focus:border-[0]"
            value={searchText}
            onChange={handleSearchInput}
          />
        </div>
        <div className="h-[2px] w-full bg-black/10" />
      </div>

      {options &&
        options
          ?.sort((opt) => (opt.value === selected ? -1 : 1))
          .map((opt, i) => (
            <div
              key={i}
              className={clsxMerge(
                "flex cursor-pointer items-center justify-between rounded-lg",
                "px-4 py-2 hover:bg-black/5"
              )}
              onClick={() => handleSelect(opt)}
            >
              <span className="ae-typography-body1 max-w-[90%] break-all">
                {opt.label}
              </span>
              {selected === opt.value && <CheckmarkIcon className="h-4 w-4" />}
            </div>
          ))}
    </div>
  );
};

const SearchSelectField: FC<SearchSelectFieldPropsI> = ({
  selectedValue = "",
  options,
  placeholder,
  disabled,
  onChange,
  onFocus = () => {},
  className,
  triggerClassName,
  isCaseInSensitive,
}) => {
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  useEffect(() => {
    if (!dropdownRef.current) {
      return;
    }

    const wrapper = dropdownRef.current as HTMLDivElement;

    const click = (ev: MouseEvent) => {
      const target = ev.target as Node;

      if (target && !wrapper.contains(target)) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", click);

    return () => {
      window.removeEventListener("click", click);
    };
  }, [dropdownRef]);

  const handleSelect = (opt: CustomSelectFieldOptionI) => {
    setShowDropdown(false);

    if (onChange) {
      onChange(selectedValue === opt.value ? "" : opt.value);
    }
  };

  const handleSearchInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = ev.target.value;
    const results = options.filter((opt) =>
      isCaseInSensitive
        ? opt.label?.toLowerCase()?.includes(searchValue?.toLowerCase())
        : opt.label.includes(searchValue)
    );

    setSearchText(searchValue);
    setDropdownOptions([...results]);
  };

  return (
    <>
      <div
        ref={dropdownRef}
        className="relative w-full rounded-lg"
        onFocus={onFocus}
      >
        <label
          className={clsxMerge(
            "relative h-[48px] cursor-pointer px-4",
            "flex items-center justify-between",
            "rounded-lg border-[1px] border-[#ccc]",
            "focus-outline-none :focus-border-none",
            "overflow-hidden",
            "transition-opacity duration-300",
            {
              "pointer-events-none opacity-40": disabled,
              "opacity-100": !disabled,
            },
            triggerClassName
          )}
          onClick={() => !disabled && setShowDropdown(!showDropdown)}
        >
          <div>
            {selectedValue ? (
              <span className="ae-typography-body1">
                {options.find((opt) => opt.value === selectedValue)?.label}
              </span>
            ) : (
              <span className="ae-typography-body1 text-black/40">
                {placeholder}
              </span>
            )}
          </div>

          <ChevronDownIcon
            className={clsxMerge(
              "relative top-[2px] w-3 text-black/40 duration-300",
              showDropdown ? "rotate-180" : "rotate-0"
            )}
          />
        </label>

        {showDropdown && (
          <DropdownContent
            options={dropdownOptions}
            selected={selectedValue}
            searchText={searchText}
            handleSelect={handleSelect}
            handleSearchInput={handleSearchInput}
            className={className}
          />
        )}
      </div>
    </>
  );
};

export default SearchSelectField;
