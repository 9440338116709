import { FC, useEffect, useMemo } from "react";
import { Sidebar } from "shared/ui";

import {
  DIALER_CALL_STATUS,
  DIALER_MODES,
  DIALER_VERSIONS,
} from "@/constants/dialer";

import { MaxedDialerWidgetConfigI } from "./interface";

import { useAccountDetails } from "@/hooks/use-account-details";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";

import { DialerV2Provider } from "@/context/dialer-v2";
import { defaultContextObject } from "@/context/dialer-v1/default-context";

import { WIDGETS, widgets } from "@/components/shared/widgets";
import { AccountDetailsModule } from "@/modules/pipeline/account-details";

import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { MaxedDialerWidgetCloseButton as CloseButton } from "@/components/shared/widgets/maxed-dialer/buttons/close-button";
import { useMaxedDialerSearchQueryParams } from "./use-search-query-params";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import { MaxedDialerDataStates } from "./data-states";

import { usePrefetchedConnectedLead } from "./use-prefetched-connected-lead";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";

const AccountDetailsSidebar: FC<MaxedDialerWidgetConfigI> = ({
  campaignId,
  accountId,
  tab,
  accountDetails,
}) => {
  const callStatus = useDialerCallStatus();

  const accountDetailsProps = useAccountDetails(
    campaignId as string,
    accountId as string,
    accountDetails
  );

  const { accountDetailsApi } = accountDetailsProps;

  const {
    campaign,
    account,
    onAccountUpdate,
    isFetchingData,
    isInitialFetchComplete,
    isErrorOccurred,
  } = accountDetailsProps;

  const isAllowedToClose = useMemo(
    () => callStatus === DIALER_CALL_STATUS.BEFORE,
    [callStatus]
  );

  const handleClose = () => {
    if (isAllowedToClose) {
      widgets.close({ id: WIDGETS.MAXED_DIALER });
    }
  };

  /**
   * For the max side of the sidebar we calculate max values for each column
   */
  return (
    <Sidebar
      className="bg-none p-0"
      headerClassName="p-0 h-[0]"
      closeButtonClassName="absolute top-5 right-5"
      containerClassName="max-w-[calc(400px+354px+874px)] w-full shadow-none"
      isOverlayClickable={isAllowedToClose}
      hideTitle
      hideBg
      disableAnimation
      handleClose={handleClose}
      closeButton={CloseButton}
    >
      <DataStatesWrapper
        api={{
          isFetching: !isInitialFetchComplete && !!isFetchingData,
          isError: accountDetailsApi.isError || isErrorOccurred,
          isSuccess: accountDetailsApi.isSuccess && !isErrorOccurred,
          isEmpty: !account || !campaign,
        }}
        empty={
          <MaxedDialerDataStates.EmptyState
            account={account}
            campaign={campaign}
          />
        }
        error={
          <MaxedDialerDataStates.ErrorState
            onClose={handleClose}
            onReload={onAccountUpdate}
          />
        }
        viewName="account"
      >
        <AccountDetailsModule tab={tab} {...accountDetailsProps} />
      </DataStatesWrapper>
    </Sidebar>
  );
};

export const WidgetMaxedDialer: FC<MaxedDialerWidgetConfigI> = (props) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const { campaignId, accountId, connectedLead, accountDetails } = props;

  useMaxedDialerSearchQueryParams(campaignId as string, accountId as string);
  usePrefetchedConnectedLead(connectedLead, accountDetails);

  useEffect(() => {
    dd.rum.log(`${LOG_CATEGORIES.MAXED_DAILER} - widget is mounted`, {
      data: { props },
    });
  }, []);

  return isGlobalDialer ? (
    <AccountDetailsSidebar {...props} />
  ) : (
    //In the scenario when Global Dialer is not enabled
    //Initializing Dialer Provider Locally
    <DialerV2Provider
      context={{
        //TODO Make it option it include spread of defaultContextObject
        ...defaultContextObject,
        campaignId,
      }}
      config={{
        version: DIALER_VERSIONS.V2,
        mode: DIALER_MODES.WIDGET_MAXED_DIALER,
      }}
    >
      <AccountDetailsSidebar {...props} />
    </DialerV2Provider>
  );
};
