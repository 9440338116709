import { FC } from "react";
import { PhoneXMarkIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardAEInfo } from "shared/ui/activity-log/cards-v2/base/ae-info";
import { HistoryCardPhoneNumbers } from "shared/ui/activity-log/cards-v2/base/phone-numbers";

const ACTIVITY_TYPE_CONFIG = {
  icon: PhoneXMarkIcon,
  title: "Phone Number DNC status changed",
};

const HistoryCardDNCProspect: FC<AccountHistoryCardBasePropsI> = ({
  activity,
  isGlenXCampaign,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />

      {activity.contact && (
        <HistoryCardContactInfo contact={activity.contact} />
      )}

      {activity.is_customer ? (
        <HistoryCardAEInfo
          aeName={activity.ae_name || "N/A"}
          isGlenXCampaign={isGlenXCampaign}
        />
      ) : (
        <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
      )}
    </div>

    <div className="flex flex-col gap-4">
      <HistoryCardPhoneNumbers activity={activity} />

      <HistoryCardCallNotes
        isCustomerNotes={activity.is_customer}
        isGlenXCampaign={isGlenXCampaign}
        notes={activity.notes}
      />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardDNCProspect;
