import { FC, PropsWithChildren, ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { ErrorBoundary } from "react-error-boundary";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

type MarkdownPreviewPropsI = PropsWithClassNameI & {
  children: null | string | undefined;
};

const schema = {
  tagNames: [
    "p",
    "strong",
    "em",
    "ul",
    "ol",
    "li",
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",

    /**
     * Details and summary are HTML5 semantic tag which allow to make a
     * collapsed section natively
     */
    "details",
    "summary",
  ],
  attributes: {
    details: [],
    summary: [],
    a: ["href", "title"], // Allow basic attributes for links
  },
};

const Link: FC<{ href?: string } & PropsWithChildren> = ({
  href,
  children,
}) => (
  <a
    href={href}
    className="ae-typography"
    target="_blank"
    rel="noopener noreferrer"
    data-theme="dark"
  >
    {children}
  </a>
);

export const MarkdownPreview: FC<MarkdownPreviewPropsI> = ({
  className,
  children,
}) => (
  <ErrorBoundary
    fallbackRender={() => (
      <div className="b-typography-detail2 text-black/60">
        Error while displaying some content.
      </div>
    )}
  >
    <ReactMarkdown
      className={className}
      rehypePlugins={[rehypeRaw, [rehypeSanitize, schema]]} // Sanitize input
      components={{
        a: Link,
        details: ({ ...props }) => {
          if (!props.children) {
            return null;
          }

          const children = props.children;

          if (Array.isArray(children)) {
            const summaryIndex = children?.findIndex(
              (child) => child.type === "summary"
            );

            const isSummaryDetected = summaryIndex >= 0;

            const summary = isSummaryDetected ? children[summaryIndex] : null;
            const rest = isSummaryDetected
              ? children.slice(summaryIndex + 1)
              : children;

            return (
              <details {...props}>
                {summary}

                {rest.map((value: string | ReactNode) => {
                  if (typeof value === "string") {
                    return (
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw, [rehypeSanitize, schema]]}
                      >
                        {value}
                      </ReactMarkdown>
                    );
                  }

                  return value;
                })}
              </details>
            );
          }

          return <details>{children}</details>;
        },
      }}
    >
      {children}
    </ReactMarkdown>
  </ErrorBoundary>
);
