import React, { FC, Fragment, ReactElement, ReactNode } from "react";
import Link from "next/link";

import { clsxMerge, safeGetDate } from "shared/lib/helpers";
import { AccountHistoryItemI } from "shared/lib/interfaces/account";

export interface AccountHistoryCardPropsI {
  className?: string;
  children: ReactNode | ReactNode[];
  createdAt?: string;
}

export interface AccountHistoryCardBasePropsI {
  isGlenXCampaign?: boolean;
  activity: AccountHistoryItemI;
  onActionClick?: () => void;
}

interface HistoryCardInfoItemPropsI {
  icon: ReactElement;
  className?: string;
  children: ReactNode | ReactNode[];
}

type CardTextInfoI = {
  isBold?: boolean;
} & (
  | {
      title: string;
      onClick?: () => void;
    }
  | {
      text: string;
      href: string;
    }
);

interface HistoryCardTextInfoItemPropsI {
  icon: ReactElement;
  subtitle: string;
  info: CardTextInfoI[];
  infoClassName?: string;
}

// Well, how would YOU name that? xD
const ONLY_FIRST_CHILDREN_VISIBLE_ON_A_SHORT_CARD_CLASS_NAME =
  "hidden first:block @xl:block";

export const HistoryCardInfoItem: FC<HistoryCardInfoItemPropsI> = ({
  icon,
  children,
}) => (
  <div className="@xl:items-start @xl:gap-4 flex items-center gap-2">
    <div className="flex w-8 justify-center">{icon}</div>
    <div className="flex flex-col gap-1">{children}</div>
  </div>
);

export const HistoryCardTextInfoItem: FC<HistoryCardTextInfoItemPropsI> = ({
  icon,
  subtitle,
  info,
  infoClassName,
}) => (
  <HistoryCardInfoItem icon={icon}>
    {info.map((item, idx) => {
      const textClassName = item.isBold
        ? "brand-typography-h5-bold"
        : "brand-typography-h5-bold @xl:brand-typography-body3"; // Meaning that text is always bold on short card

      return (
        <Fragment key={idx}>
          {"href" in item && item.href && (
            <Link
              href={item.href}
              target="_blank"
              className={clsxMerge(
                "ae-link",
                ONLY_FIRST_CHILDREN_VISIBLE_ON_A_SHORT_CARD_CLASS_NAME,
                textClassName,
                infoClassName
              )}
            >
              {item.text}
            </Link>
          )}

          {"title" in item && (
            <h5
              className={clsxMerge(
                textClassName,
                infoClassName,
                ONLY_FIRST_CHILDREN_VISIBLE_ON_A_SHORT_CARD_CLASS_NAME,
                {
                  "ae-link cursor-pointer": !!item.onClick,
                }
              )}
              onClick={item.onClick}
            >
              {item.title}
            </h5>
          )}
        </Fragment>
      );
    })}

    <p className="brand-typography-detail2 @xl:block hidden">{subtitle}</p>
  </HistoryCardInfoItem>
);

export const AccountHistoryCard: FC<AccountHistoryCardPropsI> = ({
  className,
  children,
  createdAt,
}) => {
  const isChildrenArray = Array.isArray(children);

  return (
    <div className="flex flex-col gap-3">
      {createdAt && (
        <div className="group flex w-max items-center">
          <span
            className={clsxMerge(
              "brand-typography-body3 max-w-0 overflow-hidden group-hover:block",
              "mr-1 truncate transition-all group-hover:max-w-[150px]"
            )}
          >
            {safeGetDate(createdAt, "MMM D, YYYY")} at
          </span>

          <span className="brand-typography-body3">
            {safeGetDate(createdAt, "h:mm A")}
          </span>
        </div>
      )}

      <div
        className={clsxMerge(
          isChildrenArray
            ? "flex w-full min-w-[300px] max-w-[1100px]"
            : "w-max",
          "relative overflow-hidden rounded-[10px] border bg-white p-6",

          // This container query turns cards to be row when card container size
          // is xl and above (xl in this library is 576px)
          "@xl:flex-row @xl:gap-0 flex-col gap-8",
          className
        )}
      >
        {isChildrenArray ? (
          <>
            <div className="min-w-[300px] max-w-[300px]">{children[0]}</div>

            {!!children[1] && <div className="grow">{children[1]}</div>}
          </>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
