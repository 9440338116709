export const DAYS_OF_WEEK = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
] as const;

//Project versioning issue. Should signle object after GlenX Release
export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] as const;

export const MONTHS = [
  "Jan",
  "Fed",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
] as const;

export const DATE_FORMAT = "ddd, MMM D, h:mm a z";
export const DATE_FORMAT_SHORT = "MMM D, h:mm A";
export const DATE_FORMAT_WITHOUT_TZ = "ddd, MMM D, h:mm a";
export const DATE_FORMAT_WITHOUT_TIME = "ddd, MMM D";
export const DATE_FORMAT_ONLY_TIME = "h:mm a";
export const DATE_FORMAT_ONLY_TIME_WITH_TZ = "h:mm A z";

export const DATE_FORMAT_MONTH_DAY_YEAR_TIME_WITHOUT_TZ = "MMM DD YYYY, h:mm A";
export const DATE_FORMAT_MONTH_DAY_YEAR_TIME_ZONE = "MMM D YYYY [at] h:mm a z";
export const DATE_FORMAT_MONTH_DATE_YEAR = "MMM D, YYYY";
export const DATE_FORMAT_MONTH_DATE_YEAR_SLASHED = "MM/DD/YY";
export const DATE_FORMAT_MONTH_DAY = "MMM D";
export const DATE_FORMAT_MONTH_SLASH_DAY = "MM/DD";

export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
export const ONE_SECOND_IN_MILLISECONDS = 1000;

export const BEGINNING_OF_TIME = "0001-01-01T00:00:00Z";

const ONE_MINUTE_MS = 60 * 1000;

export const TIME_INTERVALS_MS = {
  ONE_HUNDRED_MILLISECONDS: 100,
  ONE_SECOND: ONE_SECOND_IN_MILLISECONDS,
  ONE_MINUTE: ONE_MINUTE_MS,
  FIVE_MINUTES: 5 * ONE_MINUTE_MS,
  TEN_MINUTES: 10 * ONE_MINUTE_MS,
  THIRTY_MINUTES: 30 * ONE_MINUTE_MS,
  ONE_HOUR: 60 * ONE_MINUTE_MS,
  INFINITY: Infinity,
};
