import { EDIT_CONTACT_MODAL_ID } from "@/components/modals/edit-contact-modal";
import { LinkedinBadge } from "@/components/modules/calling/cards/lead-card/v1/linkedin-badge";
import { useAccountDetailsContext } from "@/components/modules/pipeline/account-details/context";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import {
  clsxMerge,
  formatUSPhoneNumber,
  getFullEntityName,
} from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import {
  CopyToClipboard,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared/ui";

export const LeadInfo = () => {
  const { contacts, account, setFocusedContact } = useAccountDetailsContext();
  const { contact: callContact } = useInCallContext();

  const contact = useMemo(
    () => contacts?.find((c) => c.id === callContact?.id),
    [contacts, callContact?.id]
  );

  const fullContactName = useMemo(() => getFullEntityName(contact), [contact]);

  const phoneNumber = useMemo(
    () => formatUSPhoneNumber(callContact?.phone),
    [callContact]
  );

  const openEditContactModal = () => {
    setFocusedContact(contact);
    modalHelpers.lazyOpen(EDIT_CONTACT_MODAL_ID);
  };

  return (
    <div className="text-white">
      <h4 className={clsxMerge("typography-body-2-black", "flex items-center")}>
        <span className="overflow-hidden text-ellipsis" title={fullContactName}>
          {fullContactName}{" "}
        </span>
        <button
          className="btn btn-circle btn-ghost btn-sm ml-2"
          onClick={openEditContactModal}
        >
          <PencilIcon className="w-5 text-white" />
        </button>
      </h4>
      <p className={clsxMerge("mb-2 typography-body-4-bold")}>
        {contact?.title || "N/A"} @ {account?.name || "N/A"}
      </p>

      <CopyToClipboard
        textToCopy={contact?.email}
        className={clsxMerge("mb-1 flex items-center typography-body-4")}
      >
        <Tooltip placement="right">
          <TooltipTrigger>
            <span className="badge relative mr-1 mt-[-2px] rounded-md border-none bg-black/10 px-1 font-semibold">
              <span className="relative top-[-1px]">@</span>
            </span>

            <span
              className="overflow-hidden text-ellipsis typography-body-4-light"
              title={contact?.email}
            >
              {contact?.email}
            </span>
          </TooltipTrigger>
          <TooltipContent className="z-[999]">
            Click to Copy "{contact?.email}"
          </TooltipContent>
        </Tooltip>
      </CopyToClipboard>

      <CopyToClipboard
        textToCopy={phoneNumber}
        className={clsxMerge("mb-1 flex items-center typography-body-4")}
      >
        <Tooltip placement="right">
          <TooltipTrigger>
            <span
              className="overflow-hidden text-ellipsis typography-body-4-medium"
              title={phoneNumber}
            >
              {phoneNumber}
            </span>
          </TooltipTrigger>
          <TooltipContent className="z-[999]">
            Click to Copy " {phoneNumber}"
          </TooltipContent>
        </Tooltip>
      </CopyToClipboard>

      <div className="flex items-center">
        <LinkedinBadge
          url={contact?.linkedin_url || contact?.linkedin}
          className="bg-[rgba(1,77,254,0.80)] text-white"
          isShowUrl
        />
      </div>
    </div>
  );
};
