// This image is often used as a background image for intro modals top section
import _mapValues from "lodash/mapValues";

import {
  ACCOUNT_DISPOSITION_STATUS_LABELS,
  ACCOUNT_DISPOSITION_STATUSES,
  MEETING_OUTCOMES,
  MEETING_OUTCOMES_MAP,
  MEETING_OUTCOMES_MAP_GLENX,
} from "./account";
import {
  AccountDispositionStatusI,
  MeetingOutcomeI,
} from "shared/lib/interfaces/account";

export const STAR_IMG_BG_CLASSNAME =
  "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/shared-ui/modals/star_background.webp')]";

const DARK_STATUS_STYLE = "bg-black";

export const ACCOUNT_DISPOSITION_STATUS_PILL_PARAMS = _mapValues(
  {
    [ACCOUNT_DISPOSITION_STATUSES.COLD_LEAD]: "bg-[#67A9F8]",
    [ACCOUNT_DISPOSITION_STATUSES.NURTURE]: "bg-[#F8A467]",
    [ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED]: "bg-[#F86767]",
    [ACCOUNT_DISPOSITION_STATUSES.FEEDBACK_RECEIVED]: DARK_STATUS_STYLE,
    [ACCOUNT_DISPOSITION_STATUSES.DISQUALIFIED]: DARK_STATUS_STYLE,
  },
  (style, key) => ({
    style,
    label: ACCOUNT_DISPOSITION_STATUS_LABELS[key as AccountDispositionStatusI],
  })
);

export const ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS = _mapValues(
  {
    [MEETING_OUTCOMES.NO_SHOW]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.PENDING]: "bg-[#F86767]",
    [MEETING_OUTCOMES.RESCHEDULED]: "bg-[#F86767]",
    [MEETING_OUTCOMES.CANCELED]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.UNQUALIFIED]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.QUALIFIED]: "bg-[#83D565]",
  },
  (style, key) => ({
    style,
    label: MEETING_OUTCOMES_MAP[key as MeetingOutcomeI],
  })
);

export const ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS_GLENX = _mapValues(
  {
    [MEETING_OUTCOMES.NO_SHOW]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.PENDING]: "bg-[#F86767]",
    [MEETING_OUTCOMES.RESCHEDULED]: "bg-[#F86767]",
    [MEETING_OUTCOMES.CANCELED]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.UNQUALIFIED]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.QUALIFIED]: "bg-[#83D565]",
  },
  (style, key) => ({
    style,
    label: MEETING_OUTCOMES_MAP_GLENX[key as MeetingOutcomeI],
  })
);
