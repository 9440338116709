import queryString from "query-string";
import { LoadingModule } from "@/components/modules/loading";

import { useEffect, useMemo, useState } from "react";
import { checkIfClient } from "shared/lib/helpers";

import {
  useAuth0,
  Auth0Provider,
  AuthorizationParams,
} from "@auth0/auth0-react";

import { setCookie } from "cookies-next";
import { COOKIES_GLCC_ACCESS_TOKEN } from "@/constants/cookies";
import { dd } from "@/helpers/datadog";
import { useInterval } from "shared/lib/hooks";
import Router from "@/helpers/router";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { LocalStorage } from "@/helpers/local-storage";
import { DayJs } from "shared/lib/helpers/date";

import { parseJwt, getExpirationTime } from "shared/lib/helpers/jwt";
import { ROUTES_EXPECTED_IGNORE_AUTH_LOGS } from "./constants";

/**
 * Removes Auth0-related items from the local storage if the current environment is a client.
 *
 * This function performs the following operations:
 * 1. Checks if the current environment is a client by calling `checkIfClient()`.
 * 2. Iterates over all entries in `localStorage`.
 * 3. Filters out the keys that include the substring "auth0".
 * 4. Removes each of the filtered items from `localStorage`.
 *
 * This is typically used to clean up authentication-related data stored in `localStorage`.
 *
 * @returns {void} This function does not return a value.
 */
export const auth0CleanUser = () => {
  if (checkIfClient()) {
    Object.entries(localStorage)
      .map((arr) => arr?.[0])
      .filter((lsKey) => lsKey?.includes("auth0"))
      .forEach((lsKey) => localStorage?.removeItem(lsKey));
  }
};

/**
 * Verifies the validity of an access token.
 *
 * This function performs the following checks:
 * 1. Ensures that the access token is not empty.
 * 2. Parses the JWT from the access token and extracts the expiration time.
 * 3. Checks if the token has expired based on the current date.
 * 4. Logs errors if the token is empty, expired, or if any issues occur during processing.
 *
 * @param {string} accessToken - The access token to verify.
 * @returns {boolean} Returns `true` if the token is valid and not expired; `false` otherwise.
 */
const verifyAccessToken = (accessToken: string) => {
  if (!accessToken) {
    dd.error(`${ERROR_CATEGORIES.AUTH} - Auth0 returned an empty access token`);
    return false;
  }

  try {
    const parsedJwt = parseJwt(accessToken as string);

    const expirationTime = getExpirationTime(parsedJwt);

    const { date: expirationDate } = expirationTime;

    if (DayJs().isAfter(DayJs(expirationDate))) {
      dd.error(
        `${ERROR_CATEGORIES.AUTH} - Auth0 returned already expiredToken`,
        { data: { expirationTime, accessToken, parsedJwt } }
      );
      return false;
    }
  } catch (e) {
    dd.error(
      `${ERROR_CATEGORIES.AUTH} - verifyAccessToken failed to process access token`,
      {
        data: {
          accessToken,
          error: e,
        },
      }
    );

    return false;
  }

  return true;
};

const Auth0LibProvider = ({ children }: { children?: any }) => {
  const onRedirectCallback = (appState: any) => {
    if (window.location.href.includes(`?code`) && appState?.returnTo) {
      window.location.href = appState?.returnTo;
    }

    window.history.replaceState(
      {},
      document.title,
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

  const authorizationParams = useMemo(() => {
    const params: AuthorizationParams = {
      audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      redirect_uri: process.env.NEXT_PUBLIC_DOMAIN,
    };

    // parse url to check if query param "screen_hint" exists.
    // in that case, we must pass it to auth0 authorizationParams to tell it to open the auth UI on the specified signup screen
    // (eg. show sign UP view instead of sign IN)
    if (checkIfClient()) {
      const { screen_hint } = queryString.parse(
        new URL(window?.location?.href)?.search
      );

      if (screen_hint && typeof screen_hint === "string") {
        params.screen_hint = screen_hint;
      }
    }

    return params;
  }, []);

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL as string}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
      authorizationParams={authorizationParams}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const AuthLocalProvider = ({ children }: { children?: any }) => {
  const {
    user,
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [isTokenAquired, setIsTokenAquired] = useState(false);

  const logout = () => {
    if (checkIfClient()) {
      window.location.href = Router.logout();
    }
  };

  const getToken = async () => {
    const isIgnoreLogs = ROUTES_EXPECTED_IGNORE_AUTH_LOGS.includes(
      window.location.pathname
    );

    try {
      const accessToken = await getAccessTokenSilently();

      if (accessToken) {
        const LS = new LocalStorage();
        setCookie(COOKIES_GLCC_ACCESS_TOKEN, accessToken);
        LS.auth0TokenUpdatedAt = DayJs().toISOString();
        setIsTokenAquired(true);

        /**
         * Fallback scenario where accessToken is already expired
         * https://github.com/auth0/auth0-react/issues/464
         * https://github.com/auth0/auth0-react/blob/main/FAQ.md
         */
        if (!isIgnoreLogs) {
          verifyAccessToken(accessToken);
        }
      } else {
        setCookie(COOKIES_GLCC_ACCESS_TOKEN, "");
        logout();
      }
    } catch (e) {
      if (!isIgnoreLogs) {
        dd.rum.error(
          `${ERROR_CATEGORIES.AUTH} User failed to acquire access token`,
          { user, error: e }
        );
      }

      setCookie(COOKIES_GLCC_ACCESS_TOKEN, "");
      logout();
    }
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      if (checkIfClient()) {
        loginWithRedirect({ appState: { returnTo: window.location.href } });
      }
    }

    if (error) {
      console.log("Error:", error);
      console.log("logout");

      if (checkIfClient()) {
        logout();
      }
    }

    if (isAuthenticated && user?.email && !error) {
      getToken();
    }
  }, [user, isAuthenticated, isLoading, error]);

  useInterval(
    async () => {
      const LS = new LocalStorage();
      const tokenUpdatedAt = LS.auth0TokenUpdatedAt;

      const isUpdatedRequired = !tokenUpdatedAt
        ? true
        : DayJs().isAfter(DayJs(tokenUpdatedAt).add(30, "seconds"));

      if (isUpdatedRequired && isAuthenticated && user?.email && !error) {
        getToken();
      }
    },
    10 * 1000,
    [user, isAuthenticated, error]
  );

  return user?.email && isAuthenticated && isTokenAquired ? (
    children
  ) : (
    <LoadingModule />
  );
};

export const AuthProvider = ({
  children,
  isAuth0Only,
}: {
  children?: any;
  isAuth0Only?: boolean;
}) => (
  <Auth0LibProvider>
    {isAuth0Only ? children : <AuthLocalProvider>{children}</AuthLocalProvider>}
  </Auth0LibProvider>
);
