import { useEffect, useRef } from "react";

/**
 * This hook is used to run a useEffect only once when a condition is met
 * Condition is any to simplify DX - developers are able to pass any truthy/falsy
 * value, which is then cast to boolean
 *
 * @param callback - any function including async, because we're not calling it
 * directly as an EffectCallback
 *
 * @param rawCondition - a condition, either truthy or falsy, which will be cast
 * to boolean to determine if the callback should run
 */
export const useEffectOnceWhen = (
  callback: (...args: unknown[]) => unknown,
  rawCondition: unknown
) => {
  const condition = Boolean(rawCondition);
  const isEffectExecutedRef = useRef<boolean>(false);

  useEffect(() => {
    if (condition && !isEffectExecutedRef.current) {
      isEffectExecutedRef.current = true;
      callback();
    }
  }, [condition, callback]);
};
