import { FC } from "react";

import { Spinner } from "shared/ui/spinners/spinner";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface CenteredSpinnerPropsI extends PropsWithClassNameI {
  spinnerClassName?: string;
}

const CenteredSpinner: FC<CenteredSpinnerPropsI> = ({
  className,
  spinnerClassName,
}) => (
  <div className={clsxMerge("relative w-full", className)}>
    <Spinner
      className={clsxMerge(
        "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
        spinnerClassName
      )}
    />
  </div>
);

export default CenteredSpinner;
