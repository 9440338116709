import { useState, useEffect, FC } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import has from "lodash/has";

import { ChevronRightIcon } from "shared/ui/icons";

import { transformNewLines } from "shared/lib/helpers/markdown";
import { CampaignI } from "@/interfaces/campaign";
import { checkIfClient, clsxMerge } from "shared/lib/helpers";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import { MarkdownPreview } from "shared/ui/markdown/preview";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface SBActiveStatePropsI extends PropsWithClassNameI {
  campaign?: CampaignI;
  activeIndex?: number;

  isVisible?: boolean;
  hideToggle?: boolean;

  contentContainerClassName?: string;
  tabsContainerClassName?: string;
  topGradientClassName?: string;
  bottomGradientClassName?: string;

  onClick?: () => void;
}

// Note - also supplementary text data type key values
const TABS = ["scripts", "personas", "objections", "qualifications"];
const TABS_TOOLTIPS = {
  objections:
    "If you have any other objections or questions you run into, you can always message the company in the dedicated slack channel for the campaign for live questions.",
};

export const getMarkdown = (campaign?: CampaignI, tabIndex?: number) => {
  if (tabIndex === 3) {
    return transformNewLines(
      campaign?.qualification_criterias
        ?.map((qc) => `- ${qc.question}`)
        .join("\r\n\n") || ""
    );
  }

  return transformNewLines(
    campaign?.supplementary_texts?.find(
      (st) => st.text_type === TABS[tabIndex || 0]
    )?.text_value || ""
  );
};

const SBActiveState: FC<SBActiveStatePropsI> = ({
  campaign,
  activeIndex = 0,

  isVisible = true,
  hideToggle,

  className,
  contentContainerClassName,
  tabsContainerClassName,
  topGradientClassName,
  bottomGradientClassName,

  onClick = () => {},
}) => {
  const [tabIndex, setTabIndex] = useState(activeIndex);
  const [updatedMarkdown, setMarkdown] = useState(
    getMarkdown(campaign, tabIndex)
  );

  useEffect(() => {
    if (activeIndex !== tabIndex && isVisible) {
      setTabIndex(activeIndex);
    }
  }, [activeIndex, isVisible]);

  useEffect(() => {
    if (checkIfClient()) {
      setMarkdown(getMarkdown(campaign, tabIndex));
    }
  }, [tabIndex, campaign]);

  const tabHandler = (key: number) => () => setTabIndex(key);

  return (
    <div
      className={clsxMerge(
        "overflow-hidden px-2 py-4 text-white transition-[width,opacity,height] duration-500",
        {
          "w-[340px] opacity-100": isVisible,
          "w-[48px] opacity-0": !isVisible,
        },
        className
      )}
    >
      <div>
        {!hideToggle && (
          <div
            className={clsxMerge(
              "group link mb-6 flex w-36 items-center text-white/60 no-underline",
              "transition-opacity",
              {
                "opacity-0": !isVisible,
                "opacity-100 delay-200 duration-200": isVisible,
              }
            )}
            onClick={onClick}
          >
            <span className="ae-typography-button3">Hide resources</span>{" "}
            <ChevronRightIcon className="relative left-0 ml-2 h-[10px] transition-[left] group-hover:left-1" />
          </div>
        )}

        <div
          className={clsxMerge(
            "TABS mb-0 flex gap-x-3 p-0 ",
            "transition-opacity",
            {
              "opacity-0": !isVisible,
              "opacity-100 delay-300 duration-300": isVisible,
            },
            tabsContainerClassName
          )}
        >
          {TABS.map((t, i) => {
            const tabContent = has(TABS_TOOLTIPS, t) ? (
              <Tooltip colorScheme="light" key={i}>
                <TooltipTrigger className="capitalize">{t}</TooltipTrigger>
                <TooltipContent>
                  <div className="max-w-[200px] text-center">
                    {TABS_TOOLTIPS[t as string] as any}
                  </div>
                </TooltipContent>
              </Tooltip>
            ) : (
              t
            );

            return (
              <a
                key={i}
                className={twMerge(
                  clsx(
                    "ae-typography-button2 tab tab-bordered px-0 last:mr-0",
                    {
                      "text-white/60": i !== tabIndex,
                      "text-white": i === tabIndex,
                    }
                  )
                )}
                onClick={tabHandler(i)}
              >
                <span
                  className={twMerge(
                    clsx(
                      "border-b-[1px] border-transparent pb-2 capitalize transition-[border] duration-700",
                      {
                        "border-white": i === tabIndex,
                      }
                    )
                  )}
                >
                  {tabContent}
                </span>
              </a>
            );
          })}
        </div>

        <div
          className={clsx("relative transition-[opacity,height]", {
            "opacity-0": !isVisible,
            "opacity-100 delay-500 duration-500": isVisible,
          })}
        >
          <div
            className={clsxMerge(
              "absolute left-0 top-0 h-6 w-full bg-gradient-to-b from-black to-transparent",
              topGradientClassName
            )}
          />
          <div
            className={twMerge(
              clsx(
                "ae-typography overflow-auto py-6 ",
                "transition-[max-height] duration-500",
                {
                  "max-h-[calc(100vh-155px)]":
                    isVisible && !contentContainerClassName?.includes("max-h"),
                  "max-h-[calc(50vh-155px)]":
                    !isVisible && !contentContainerClassName?.includes("max-h"),
                },
                contentContainerClassName
              )
            )}
          >
            <MarkdownPreview>{updatedMarkdown}</MarkdownPreview>
          </div>
          <div
            className={clsxMerge(
              "absolute bottom-0 left-0 h-6 w-full bg-gradient-to-t from-black to-transparent",
              bottomGradientClassName
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SBActiveState;
