import {
  LabelValue,
  createRecord,
} from "@/modules/pipeline/account-details/sidebar/components/label-value";
import { Fragment, useMemo } from "react";

import { fixUrl } from "shared/lib/helpers";
import { formatMoneyDollars, formatNumbers } from "shared/lib/helpers/numbers";
import { AccountDetailsStatusI, AccountI } from "shared/lib/interfaces/account";
import { AccountDispositionStatusSmallPill } from "@/modules/pipeline/account-details/sidebar/components/status-pill";
import { getAccountDispositionStatusParams } from "shared/lib/helpers/disposition";
import { HorizontalDivider } from "./horizontal-diviider";

export const AccountTopSectionContent = ({
  account,
  accountStatus,
}: {
  account?: AccountI;
  accountStatus?: AccountDetailsStatusI;
}) => {
  const accountInfoItems = [
    createRecord(
      "Revenue",
      account?.revenue
        ? `${formatMoneyDollars(account.revenue, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`
        : null
    ),
    createRecord("HQ State", account?.hq_state || null),
    createRecord(
      "Employees",
      account?.employee_count
        ? `${formatNumbers(account?.employee_count)}`
        : null
    ),
    createRecord("Industry", account?.industry || null),
  ];

  const accountStatusBadge = useMemo(
    () => (
      <AccountDispositionStatusSmallPill
        {...getAccountDispositionStatusParams(accountStatus, true)}
      />
    ),
    [accountStatus?.status]
  );

  return (
    <div className="animate-fadein px-6 pt-6">
      <a
        className="mb-1 block typography-header-5-semibold"
        target="_blank"
        href={fixUrl(account?.website)}
        rel="noreferrer"
      >
        {account?.name}
      </a>

      <div className="flex w-full items-end gap-x-3">
        <LabelValue
          label="Status"
          value={accountStatusBadge}
          valueClassName="mb-0"
        />

        <HorizontalDivider />

        {accountInfoItems?.map((record, i) => (
          <Fragment key={i}>
            <LabelValue {...record} />
            {record?.value && <HorizontalDivider />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
