import { FC, useMemo } from "react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import { ListPillI } from "@/api/routes/list";
import { ControlledDropdown } from "shared/ui";
import { clsxMerge } from "shared/lib/helpers";
import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";

interface AddUserToListSidebarListsDropdownPropsI
  extends ControllableComponentPropsI<ListPillI | null> {
  lists?: ListPillI[];
  disabled?: boolean;
}

export const CREATE_NEW_LIST_OPTION_ID = "create-new-list";
export const CREATE_NEW_LIST_OPTION = {
  name: "Create new list",
  id: CREATE_NEW_LIST_OPTION_ID,
} as unknown as ListPillI;

export const AddUserToListSidebarListsDropdown: FC<
  AddUserToListSidebarListsDropdownPropsI
> = ({ lists, value, onChange, disabled }) => {
  const options = useMemo(() => {
    if (!lists) {
      return [];
    }

    return [CREATE_NEW_LIST_OPTION, ...lists];
  }, [lists]);

  const dropdownLabel = value ? value.name : "Select list";

  const handleListChange = (list: ListPillI) => {
    onChange(list.id === value?.id ? null : list);
  };

  return (
    <ControlledDropdown
      closeOnContentClick
      className={clsxMerge("dropdown-end w-full", {
        "disabled cursor-not-allowed opacity-60": disabled,
      })}
    >
      {() => (
        <>
          <label
            tabIndex={0}
            title={dropdownLabel}
            className="mr-1 block w-full text-start text-black/40"
          >
            <div
              className={clsxMerge(
                "flex justify-between",
                "b-typography-body3 truncate text-black/60",
                "rounded-lg border-2 bg-white",
                "w-full p-4"
              )}
            >
              {dropdownLabel}
              <ChevronDownIcon className="w-4" />
            </div>
          </label>

          <div
            tabIndex={0}
            className="dropdown-content menu w-full rounded-lg bg-base-100 shadow-lg"
          >
            {options.map((list, i) => {
              const isCreateListOption = list.id === CREATE_NEW_LIST_OPTION_ID;

              return (
                <label
                  key={i}
                  className={clsxMerge(
                    "label cursor-pointer hover:bg-black/5",
                    "w-full p-4",
                    "ae-typography-body2 label-text"
                  )}
                  onClick={() => handleListChange(list)}
                >
                  <div
                    className={clsxMerge(
                      "flex items-center gap-1",
                      isCreateListOption && "text-[#014DFE]"
                    )}
                  >
                    {isCreateListOption && (
                      <PlusIcon className="w-5 text-[#014DFE]" />
                    )}

                    {list.name}
                  </div>

                  {value?.id === list.id && <CheckIcon className="w-4" />}
                </label>
              );
            })}
          </div>
        </>
      )}
    </ControlledDropdown>
  );
};
