import { clsxMerge } from "shared/lib/helpers";

export const CallButtonMenuHeader = () => (
  <div
    className={clsxMerge(
      "flex w-full cursor-default flex-col items-start gap-y-2 p-0 pb-1 pt-4",
      "bg-white"
    )}
  >
    <span className="px-4 typography-body-4-light">Select lead to call</span>
  </div>
);
