import { checkIfClient } from "shared/lib/helpers";
import throttle from "lodash/throttle";

export const createKeyValuePair = (key: string, value: string) => ({
  key,
  value,
});

export const triggerModal = (modalId: string) => {
  if (checkIfClient()) {
    (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
  }
};

/**
 * Make it easy to disable double-clicking on a button with a throttle higher order function.
 *
 * @param fn
 * @returns
 */
export const withDoubleClickDisabled = (fn: (...args: any[]) => any) =>
  throttle(fn, 1000, {
    leading: true,
    trailing: false,
  });
