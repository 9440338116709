import { FC, useMemo } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardAEInfo } from "shared/ui/activity-log/cards-v2/base/ae-info";

const HistoryCardAENoteV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
  isGlenXCampaign,
}) => {
  const config = useMemo(
    () => ({
      icon: PencilSquareIcon,
      title: isGlenXCampaign ? "Meeting taker note" : "AE note",
    }),
    [isGlenXCampaign]
  );

  return (
    <AccountHistoryCard createdAt={activity.created_at}>
      <div className="flex flex-col gap-4">
        <HistoryCardActivityType config={config} />
        <HistoryCardAEInfo
          aeName={activity.ae_name || "N/A"}
          isGlenXCampaign={isGlenXCampaign}
        />
      </div>

      <div className="flex flex-col gap-4">
        <HistoryCardCallNotes isCustomerNotes notes={activity.notes} />
      </div>
    </AccountHistoryCard>
  );
};

export default HistoryCardAENoteV2;
