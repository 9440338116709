import { AccountHistoryItemI } from "shared/lib/interfaces/account";
import { Checkbox } from "shared/ui/ae-user-input";
import { clsxMerge, formatUSPhoneNumber } from "shared/lib/helpers";

const SimpleCheckbox = Checkbox();

export const HistoryCardPhoneNumbers = ({
  activity,
}: {
  activity: AccountHistoryItemI;
}) => {
  return (
    <div className="flex items-center gap-x-2">
      {activity?.dnc_numbers?.map((dncNumber, i) => (
        <SimpleCheckbox
          key={i}
          disabled
          name={dncNumber?.phone}
          label={formatUSPhoneNumber(dncNumber?.phone)}
          value={dncNumber?.dnc}
          inputProps={{ checked: dncNumber?.dnc }}
          labelClassName={clsxMerge("typography-body-4-bold")}
          labelTextClassName={clsxMerge("ml-2", {
            "text-black/60 line-through": dncNumber?.dnc,
          })}
        />
      ))}
    </div>
  );
};
