import { useMemo } from "react";

import { useDialerDirectCall } from "@/hooks/dialer/use-dialer-direct-call";
import { CallButton as MiniDialerCallButton } from "@/modules/calling/control-menu/v2/buttons/call-button";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
// import { getFullEntityName } from "shared/lib/helpers";
// import { PhoneIcon } from "shared/ui/icons";
// import ButtonMenuItem from "@/components/shared/button-menu-item";
import { ButtonMenu } from "shared/ui";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { withDoubleClickDisabled } from "@/helpers/utils";
import { CallButtonMenuContactBlock } from "@/modules/pipeline/account-details/control-menu/buttons/call-button/contact-block";
import { CallButtonMenuHeader } from "@/modules/pipeline/account-details/control-menu/buttons/call-button/menu-header";

// Make it easy to disable double-clicking on a button with a throttle higher
// order function.

export const CallButton = () => {
  const status = useDialerCallStatus();
  const { campaign, contactsForContextMenu } = useAccountDetailsContext();

  const { startDirectCall } = useDialerDirectCall();
  const { contact: callingContact } = useInCallContext();
  const primaryLead = useMemo(
    () => contactsForContextMenu.find((contact) => contact.is_primary_lead),
    [contactsForContextMenu]
  );

  const startCallWithPrimaryLeadIfAvailable = useMemo(
    () =>
      withDoubleClickDisabled(() => {
        /**
         * This branch is responsible for handling the "Call Again" case
         */
        if (status === DIALER_CALL_STATUS.AFTER && callingContact) {
          return startDirectCall({
            contact: {
              id: callingContact.id as string,
              phone: callingContact.phone,
            },
          });
        }
      }),
    [
      contactsForContextMenu,
      startDirectCall,
      callingContact,
      status,
      primaryLead,
    ]
  );

  const addButtonMenuItems = useMemo(
    () => [
      <CallButtonMenuHeader />,
      ...(contactsForContextMenu || [])?.map((contact) => (
        <CallButtonMenuContactBlock contact={contact} />
      )),
    ],
    [contactsForContextMenu, campaign]
  );

  if (DIALER_CALL_STATUS.DURING === status) {
    return <MiniDialerCallButton />;
  }

  return DIALER_CALL_STATUS.AFTER === status ? (
    // Either if there's only one contact in the context menu (just call it) or
    // if we're in the post-call state (call again), we should render a single
    // call button.
    <MiniDialerCallButton onStartCall={startCallWithPrimaryLeadIfAvailable} />
  ) : (
    <div className="btn-group">
      <ButtonMenu
        containerClassName="dropdown-top"
        contentClassName="mb-2 pb-4"
        position="end"
        className="p-0"
        menuItemClassName="[&:active>*]:active:bg-white"
        // Showing items on hover enabled for a case when clicking the button
        // fires calling a primary lead if there is any
        isHoverMode={!!primaryLead && contactsForContextMenu.length > 1}
        renderTrigger={() => (
          <MiniDialerCallButton
            isDisabled={contactsForContextMenu.length === 0}
            onStartCall={startCallWithPrimaryLeadIfAvailable}
          />
        )}
        items={addButtonMenuItems}
      />
    </div>
  );
};
