import React, { useState, useEffect } from "react";

import { Field, Form, Formik } from "formik";
import { SelectField, TextArea } from "shared/ui/ae-user-input";

import Calendar from "react-calendar";
import clsx from "clsx";
import * as Yup from "yup";

import { TIMEZONES as OFFICIAL_TIMEZONES } from "shared/lib/constants/timezones";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import advanced from "dayjs/plugin/advancedFormat";
import { checkIfClient } from "shared/lib/helpers";
import { convertTime12to24, DayJs } from "shared/lib/helpers/date";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(advanced);

const EnhancedTextArea = TextArea(Field);

export const calendarFormValuesToISODate = (
  date: string,
  time: string,
  timezone: string
) => {
  const { hours, minutes } = convertTime12to24(time);

  const customNextTouchTimeISO = DayJs(date)
    .hour(hours)
    .minute(minutes)
    .tz(
      OFFICIAL_TIMEZONES.find((tz) => tz.fullZoneName === timezone)?.tzCode,
      true
    )
    .toISOString();

  return customNextTouchTimeISO;
};

export interface CallingCalendarFormButtonI {
  text: string;
  type: "button" | "label";
  htmlFor?: string;
  onClick?: () => void;
}

interface FormI {
  notes: string;
}

export interface CalendarDateI {
  date: string;
  time: string;
  timezone: string;
}

export const TIMEZONES_MAP = {
  "Eastern Standard Time": "05:00",
  "Central Standard Time": "06:00",
  "Mountain Standard Time": "07:00",
  "Pacific Standard Time": "08:00",
  "Alaska Standard Time": "09:00",
  "Hawaii Standard Time": "10:00",
};

export const TIMEZONES = Object.keys(TIMEZONES_MAP);
export const TIME = [
  "7:00 am",
  "7:30 am",
  "8:00 am",
  "8:30 am",
  "9:00 am",
  "9:30 am",
  "10:00 am",
  "10:30 am",
  "11:00 am",
  "11:30 am",
  "12:00 pm",
  "12:30 pm",
  "1:00 pm",
  "1:30 pm",
  "2:00 pm",
  "2:30 pm",
  "3:00 pm",
  "3:30 pm",
  "4:00 pm",
  "4:30 pm",
  "5:00 pm",
  "5:30 pm",
  "6:00 pm",
  "6:30 pm",
  "7:00 pm",
];

const guessUserTimezone = () => {
  if (checkIfClient()) {
    // TODO handle daylight time
    // to simplify search replace D with S
    const zone = dayjs().format(`z`).replace("D", "S");

    return (
      OFFICIAL_TIMEZONES.find((timezone) => timezone.abbr === zone)
        ?.fullZoneName || TIMEZONES[0]
    );
  } else {
    return TIMEZONES[0];
  }
};

const convertTimeOptionAndSelectedTimezoneToTime = (
  time: string,
  date: string,
  timezone: keyof typeof TIMEZONES_MAP
) => {
  const [hh, mm] = getHHMM(time);
  const nowInSelectedTimezone = dayjs.tz(
    date,
    OFFICIAL_TIMEZONES.find(
      (t) =>
        t.utc === `-${TIMEZONES_MAP[timezone as keyof typeof TIMEZONES_MAP]}`
    )?.tzCode
  );

  const timeOption = nowInSelectedTimezone
    .set("hour", hh === 24 ? 0 : hh)
    .set("minute", mm)
    .set("second", 0);

  const isoDate = timeOption.format();

  return isoDate;
};

const getHHMM = (t: string) => {
  const [time, noon] = t.split(" ");
  const hhmm = time.split(":");
  let hh: number | string = hhmm[0];
  let mm: number | string = hhmm[1];

  hh = (noon === "pm"
    ? hh === "12"
      ? 12
      : parseInt(hh) + 12
    : parseInt(hh)) as unknown as number;
  mm = parseInt(mm);

  return [hh, mm];
};

const getAvailableTime = (
  timezone: keyof typeof TIMEZONES_MAP,
  date?: string
) => {
  const now = dayjs();

  const nowInSelectedTimezone = dayjs.tz(
    now as any,
    OFFICIAL_TIMEZONES.find((t) => t.utc === `-${TIMEZONES_MAP[timezone]}`)
      ?.tzCode
  );

  const futureDate = date
    ? dayjs.tz(
        date,
        OFFICIAL_TIMEZONES.find((t) => t.utc === `-${TIMEZONES_MAP[timezone]}`)
          ?.tzCode
      )
    : nowInSelectedTimezone;

  return TIME.filter((t) => {
    const [hh, mm] = getHHMM(t);
    const timeOption = futureDate
      .set("hour", hh === 24 ? 0 : hh)
      .set("minute", mm)
      .set("second", 0);

    return timeOption.isAfter(nowInSelectedTimezone);
  });
};

const searchTimeInAvailableTimes = (
  utcDate: string,
  availableTimes: string[]
) => {
  if (!checkIfClient() || !utcDate) {
    return;
  }

  const futureDate = dayjs(utcDate);

  const availableTimeOption = availableTimes.find((t) => {
    const [hh, mm] = getHHMM(t);
    const timeOption = futureDate
      .set("hour", hh === 24 ? 0 : hh)
      .set("minute", mm)
      .set("second", 0);

    return timeOption.isSame(futureDate, "minute");
  });

  return availableTimeOption;
};

const CLASSES = {
  PAST: "react-calendar__tile--past",
  NOT_AVAILABLE_FOR_BOOKING: "react-calendar__tile--not-available",
};

const CalendarForm = ({
  title,
  description,
  DetailsSection,

  className,

  initialTime,
  initialDate,
  initialTimezone,

  cancelButton,
  successButton,

  isNotes = true,
  isNotesRequired,
  notesLabel = "Additional Notes",
  notes = "",

  isLoading,
  bookingDeadlineInMonths = 2,

  onNotesChange,
  onDateChange,
  //custom booking handling
  onFormSubmit = () => {},
}: {
  title?: string;
  description?: string;
  DetailsSection?: JSX.Element;

  className?: string;

  initialTime?: string;
  initialDate?: string;
  initialTimezone?: string;

  cancelButton?: CallingCalendarFormButtonI;
  successButton?: CallingCalendarFormButtonI;

  isNotes?: boolean;
  isNotesRequired?: boolean;
  notesLabel?: string;
  notes?: string;

  isLoading?: boolean;
  bookingDeadlineInMonths?: number | boolean;

  onNotesChange?: (notesValue: string) => void;
  onDateChange?: ({ date, time, timezone }: CalendarDateI) => void;

  onBookingSuccess?: (date: string) => void;
  onFormSubmit?: (
    date: string,
    time: string,
    timezone: string,
    notes: string,
    onSuccessCleanUp: () => void
  ) => void;
}) => {
  const [disabled, disable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(initialDate || new Date().toString());

  const [timezone, setTimezone] = useState(
    initialTimezone || guessUserTimezone()
  );
  const [time, setTime] = useState(initialTime || TIME[0]);
  const [availableTime, setAvailableTime] = useState(TIME);

  useEffect(() => {
    onDateChange?.({ date, time, timezone });
  }, [date, time, timezone]);

  const FormSchema = Yup.object().shape({
    notes: Yup.string()
      .max(1000, "Max 1000 characters")
      .when([], {
        is: () => isNotesRequired,
        then: Yup.string().required("Notes are required"),
      }),
  });

  const form = {
    notes: notes,
  };

  useEffect(() => {
    if (typeof isLoading === "boolean") {
      setLoading(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    const timezone = initialTimezone || guessUserTimezone();
    setTimezone(timezone);

    const availableTimes = getAvailableTime(
      timezone as keyof typeof TIMEZONES_MAP,
      initialDate
    );

    const initialTimeInAvailableTimes = availableTimes.includes(
      initialTime as string
    )
      ? initialTime
      : searchTimeInAvailableTimes(initialTime as string, availableTimes);

    setAvailableTime(availableTimes);
    setTime(initialTimeInAvailableTimes || availableTimes[0] || "");
  }, []);

  useEffect(() => {
    const availableTimes = getAvailableTime(
      timezone as keyof typeof TIMEZONES_MAP,
      date
    );
    setAvailableTime(availableTimes);
    setTime(availableTimes[0] || "");
  }, [date]);

  useEffect(() => {
    const availableTimes = getAvailableTime(
      timezone as keyof typeof TIMEZONES_MAP,
      date
    );
    setAvailableTime(availableTimes);

    setTime(availableTimes[0] || "");
  }, [timezone]);

  useEffect(() => {
    setTimezone(initialTimezone || guessUserTimezone());
  }, [initialTimezone]);

  useEffect(() => {
    if (initialDate) {
      const availableTimes = getAvailableTime(
        timezone as keyof typeof TIMEZONES_MAP,
        date
      );
      setAvailableTime(availableTimes);
      setDate(initialDate);
    }
  }, [initialDate]);

  useEffect(() => {
    const availableTimes = getAvailableTime(
      timezone as keyof typeof TIMEZONES_MAP,
      date
    );

    const initialTimeInAvailableTimes = availableTimes.includes(
      initialTime as string
    )
      ? initialTime
      : searchTimeInAvailableTimes(initialTime as string, availableTimes);

    setTime(initialTimeInAvailableTimes || availableTimes[0] || "");
  }, [initialTime]);

  useEffect(() => {
    disable(!time);
  }, [time]);

  const resetValues = () => {
    setDate(new Date().toString());
    setTimezone(TIMEZONES[0]);
    setTime(TIME[0]);
  };

  const handleConfirmation = async (data: FormI) => {
    const isoDate = convertTimeOptionAndSelectedTimezoneToTime(
      time,
      date,
      timezone as keyof typeof TIMEZONES_MAP
    );

    onFormSubmit(isoDate, time, timezone, data.notes, () => {
      resetValues();
    });
  };

  const calendarTileClassName = ({ date: _date }: any) => {
    const cDate = dayjs(_date);

    if (!cDate.isToday() && cDate.isBefore(dayjs(), "day")) {
      return CLASSES.PAST;
    }

    if (
      typeof bookingDeadlineInMonths === "number" &&
      cDate.isAfter(
        dayjs().add(bookingDeadlineInMonths as number, "month"),
        "day"
      )
    ) {
      return CLASSES.NOT_AVAILABLE_FOR_BOOKING;
    }

    return null;
  };

  const handleDateChange = (e: any) => {
    setDate(new Date(e).toString());
  };

  return (
    <div className={className}>
      {title && <h3 className="ae-typography-h4 mb-2">{title}</h3>}
      {description && (
        <p className="ae-typography-detail1 text-base-content/40 mb-6">
          {description}
        </p>
      )}

      {DetailsSection}

      {!!date && (
        <Calendar
          calendarType="gregory"
          view="month"
          value={new Date(date)}
          onChange={handleDateChange}
          tileClassName={calendarTileClassName}
          goToRangeStartOnSelect={false}
        />
      )}

      <div className="mb-4 mt-4 grid grid-cols-2 gap-x-8">
        <div>
          <SelectField
            selectClassName="border border-black/20 max-w-none"
            labelClassName="text-black typography-body-4-medium"
            label="Time Zone"
            selectedValue={timezone}
            options={TIMEZONES}
            onChange={(v) => setTimezone(v.target.value)}
          />
        </div>
        <div>
          <SelectField
            selectClassName="border border-black/20 max-w-none"
            labelClassName="text-black typography-body-4-medium"
            label="Time"
            selectedValue={time}
            options={availableTime}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={form}
        validationSchema={FormSchema}
        onSubmit={handleConfirmation}
      >
        {({ errors, touched, handleChange }) => (
          <Form>
            {isNotes && (
              <div>
                <EnhancedTextArea
                  name="notes"
                  label={notesLabel}
                  placeholder="Type your notes here"
                  inputClassName="max-h-[50vh] min-h-[130px]"
                  errors={errors.notes}
                  touched={touched.notes}
                  inputProps={{
                    onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
                      if (onNotesChange) onNotesChange(ev.target.value);
                      handleChange(ev);
                    },
                  }}
                />
              </div>
            )}

            <div className="mt-8 flex justify-end">
              {cancelButton?.type === "label" && (
                <label
                  htmlFor={cancelButton.htmlFor}
                  className="btn-ae-text mr-2"
                  onClick={cancelButton.onClick}
                >
                  {cancelButton.text}
                </label>
              )}
              {cancelButton?.type === "button" && (
                <button
                  className="btn-ae-text mr-2"
                  type="button"
                  onClick={cancelButton.onClick}
                >
                  {cancelButton.text}
                </button>
              )}

              {successButton?.type === "label" && (
                <label
                  htmlFor={!disabled || !loading ? successButton.htmlFor : ""}
                  className={clsx("btn-ae-default btn w-[140px]", {
                    loading,
                    disabled,
                  })}
                  onClick={successButton.onClick}
                >
                  {successButton.text}
                </label>
              )}

              {successButton?.type === "button" && (
                <button
                  type="submit"
                  disabled={loading || disabled}
                  className={clsx("btn-ae-default btn w-[140px]", {
                    loading: loading,
                  })}
                  onClick={successButton.onClick}
                >
                  {successButton.text}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CalendarForm;
