import { FC, ReactNode, useRef } from "react";
import {
  ShowMore,
  type ShowMoreRef,
  type ShowMoreToggleLinesFn,
} from "@re-dev/react-truncate";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface LabelValuePropsI extends PropsWithClassNameI {
  label?: string;
  value?: ReactNode;
  labelClassName?: string;
  valueClassName?: string;
  isTruncate?: boolean;
}

export const createRecord = (
  label?: string,
  value?: string | ReactNode,
  valueClassName?: string
) => ({ label, value, valueClassName });

export const LabelValue: FC<LabelValuePropsI> = ({
  label,
  value,
  className,
  labelClassName,
  valueClassName,
  isTruncate,
}) => {
  const ref = useRef<ShowMoreRef>(null);

  // Custom buttons can be expanded and collapsed through this method
  const toggleLines: ShowMoreToggleLinesFn = (e) => {
    ref.current?.toggleLines(e);
  };

  return value === null ? null : (
    <div className={clsxMerge("flex flex-col", className)}>
      <span
        className={clsxMerge(
          "mb-1 break-words font-medium",
          {
            "brand-typography-body1": !valueClassName?.includes("typography"),
          },
          valueClassName
        )}
      >
        {isTruncate ? (
          <ShowMore
            ref={ref}
            lines={2}
            more={
              <button
                onClick={toggleLines}
                className="brand-typography-body2 font-light"
              >
                ... Read more
              </button>
            }
            less={
              <button
                onClick={toggleLines}
                className="brand-typography-body2 font-light"
              >
                <br />
                Read less
              </button>
            }
          >
            {value}
          </ShowMore>
        ) : (
          value
        )}
      </span>

      <span
        className={clsxMerge(
          "text-[#333]",
          !labelClassName?.includes("typography") && "brand-typography-detail2",
          labelClassName
        )}
      >
        {label}
      </span>
    </div>
  );
};
