import { useCallback } from "react";
import { Portal } from "react-portal";
import { toast } from "react-hot-toast";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { DisqualifyModal } from "@/modals/disqualify-modal";
import { EditContactModal } from "@/modals/edit-contact-modal";
import { AccountSendEmailModal } from "@/modals/account-send-email-modal";
import CreateNewLeadModal from "@/modals/create-new-lead-modal";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";
import AccountCancelMeetingModal from "@/modals/account-cancel-meeting-modal";
import { AccountEditCallbackModal } from "@/modals/account-edit-callback-modal";
import { AccountBookMeetingModal } from "@/modals/account-book-meeting-modal";
import EditAccountModal from "@/modals/edit-account-modal";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { CreateNewLeadFormI } from "@/modals/create-new-lead-modal/new-lead-form";
import { glencocoClientAPI } from "@/api/glencoco";
import { handleErrorMessage } from "@/helpers/error";
import { DeleteAccountLeadConfirmationModal } from "@/components/modals/delete-account-lead-confirmation-modal";
import { AddUserToListSidebar } from "@/modules/pipeline/shared/add-user-to-list-sidebar";
import { useAddUserToListSidebarContext } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { SetUnsetPrimaryLeadConfirmationModal } from "@/modals/set-primary-lead-confirmation-modal";
import { SetContactTypeModal } from "@/modals/set-contact-type-modal";
import { EmailTemplatesSidebar } from "@/components/modules/email-templates-sidebar";
import { useEmailTemplatesSidebarContext } from "@/components/modules/email-templates-sidebar/context";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { useLocalStorage } from "@/context/local-storage";
import { useLiveTransfer } from "@/modules/pipeline/account-details/hooks/use-live-transfer";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { LIVE_TRANSFER_AMB_MODAL_ID } from "@/components/modals/account-live-transfer-modal/after-meeting-booked";
import { DNCNumbersToggleModal } from "@/components/modals/dnc-numbers-toggle-modal";

export const ACCOUNT_DETAILS_CREATE_NEW_LEAD_MODAL_ID =
  "account-details-create-new-lead-modal";

export const AccountDetailsModals = () => {
  const { clearModalAccount, modalAccount } = useAccountsContext();
  const { isLiveTransferMode, isLiveTransferIsMeetingBooked } =
    useLiveTransfer();

  const storage = useLocalStorage();

  const {
    focusedContact,
    setFocusedContact,
    campaign,
    account,
    contactsForContextMenu,
    onAccountUpdate: handleAccountUpdate,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const {
    isOpen: isAddUserToListSidebarOpen,
    onClose: closeAddUserToListSidebar,
  } = useAddUserToListSidebarContext();

  const {
    isOpen: isEmailTemplatesSidebarOpen,
    onClose: closeEmailTemplatesSidebar,
  } = useEmailTemplatesSidebarContext();

  const submitNewLeadToAccountAsBacklog = async (data: CreateNewLeadFormI) => {
    if (campaign?.id && account?.id) {
      const API = glencocoClientAPI();

      const resp = await API.addLeadToAccount(campaign?.id, account?.id, {
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
        phone: data.phone,
        mobile: data.mobile,
        email: data.email,
        linkedin_url: data.linkedin_url,
      }).catch((e) => e);

      if (resp.status === 200) {
        toast.success("Success");
        return true;
      } else {
        handleErrorMessage(
          resp?.response?.data?.error_code as number,
          "Failed to add new lead. Please contact Glencoco."
        );
      }
    }
    return false;
  };

  // Call this function when we need to both update account data and reload account history
  const onModalActionSuccess = useCallback(() => {
    handleAccountUpdate();
    reloadAccountHistory();

    if (focusedContact) {
      setFocusedContact(undefined);
    }
  }, [handleAccountUpdate, reloadAccountHistory, focusedContact]);

  const onModalBookMeetingSuccess = useCallback(() => {
    if (isLiveTransferMode && !isLiveTransferIsMeetingBooked) {
      setTimeout(() => {
        modalHelpers.open(LIVE_TRANSFER_AMB_MODAL_ID);
      }, 1000);
    }

    onModalActionSuccess();
  }, [onModalActionSuccess, isLiveTransferMode, isLiveTransferIsMeetingBooked]);

  /**
   * Clear persisted data for modals. Right now we save data for AccountSendEmailModal
   * and CreateNewLeadModal
   */
  useEffectOnce(() => {
    storage.emailModalContent = null;
    storage.newLeadModalPersistedValues = null;
  });

  return (
    <Portal>
      {campaign && account && (
        <DisqualifyModal
          campaignId={campaign?.id}
          account={account}
          contacts={contactsForContextMenu}
          focusedContactId={focusedContact?.id}
          onSuccess={onModalActionSuccess}
        />
      )}

      {focusedContact && (
        <>
          <EditContactModal
            campaign={campaign}
            contact={focusedContact}
            onClose={() => setFocusedContact(undefined)}
            onSubmitSuccess={onModalActionSuccess}
          />

          <SetUnsetPrimaryLeadConfirmationModal
            campaign={campaign}
            account={account}
            contact={focusedContact}
            onSuccess={onModalActionSuccess}
          />

          <AccountSendEmailModal
            campaignId={campaign?.id as string}
            contact={focusedContact}
            onSuccess={onModalActionSuccess}
          />

          <DeleteAccountLeadConfirmationModal
            campaignId={campaign?.id}
            contact={focusedContact}
            onSuccess={() => {
              setFocusedContact(undefined);
              onModalActionSuccess();
            }}
          />

          <SetContactTypeModal
            campaignId={campaign?.id}
            accountId={account?.id}
            contactId={focusedContact?.id}
            contactType={focusedContact.contact_disposition_type}
            onSuccess={onModalActionSuccess}
          />
        </>
      )}

      {/* THESE MODALS HANDLE NEW ACCOUNT LEAD CREATION */}
      <CreateNewLeadModal
        modalId={ACCOUNT_DETAILS_CREATE_NEW_LEAD_MODAL_ID}
        campaign={campaign}
        account={account}
        handleNewLeadSubmitAsBacklog={submitNewLeadToAccountAsBacklog}
        onSuccess={onModalActionSuccess}
      />

      {/* THESE MODALS HANDLE UPDATING AN EXISTING ACCOUNT  */}
      {!!modalAccount && (
        <>
          {modalAccount.accountStatus ===
            ACCOUNT_STATUSES.MEETING_SCHEDULED && (
            <AccountCancelMeetingModal
              onClose={clearModalAccount}
              onSuccess={onModalActionSuccess}
            />
          )}

          {modalAccount.targetAccountStatus === ACCOUNT_STATUSES.CALLBACK && (
            <AccountEditCallbackModal
              followUpTime={modalAccount.followUpTime}
              onClose={clearModalAccount}
              onSuccess={onModalActionSuccess}
            />
          )}

          {modalAccount.targetAccountStatus ===
            ACCOUNT_STATUSES.MEETING_SCHEDULED && (
            <AccountBookMeetingModal
              onClose={clearModalAccount}
              onSuccess={onModalBookMeetingSuccess}
            />
          )}
        </>
      )}

      <EditAccountModal
        campaign={campaign}
        account={account}
        onSubmitSuccess={onModalActionSuccess}
      />

      <AddUserToListSidebar
        isOpen={isAddUserToListSidebarOpen}
        onClose={closeAddUserToListSidebar}
      />

      <EmailTemplatesSidebar
        isOpen={isEmailTemplatesSidebarOpen}
        onClose={closeEmailTemplatesSidebar}
      />

      <DNCNumbersToggleModal
        campaign={campaign}
        contact={focusedContact}
        onSuccess={onModalActionSuccess}
      />
    </Portal>
  );
};
