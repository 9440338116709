import { ContactDetailI } from "@/interfaces/accounts";

import { FC } from "react";
import { Modal } from "shared/ui";
import { Checkbox } from "shared/ui";
import { ModalChildrenFnPropsI } from "shared/ui/modal";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextArea } from "shared/ui/ae-user-input";
import { glencocoClientAPI } from "@/api/glencoco";
import { CampaignI } from "@/interfaces/campaign";
import toast from "react-hot-toast";

export const DNC_NUMBERS_TOGGLE_MODAL_ID = "dnc-numbers-toggle-modal";

const EnhancedCheckboxButton = Checkbox(Field);
const EnhancedTextarea = TextArea(Field);

const FormValidationSchema = Yup.object().shape({
  note: Yup.string().required("A note is required."),
});

interface DNCNumbersToggleModalPropsI {
  campaign?: CampaignI;
  contact?: ContactDetailI;
  onSuccess?: () => void;
}

interface FormValuesI {
  phone?: boolean;
  mobile?: boolean;
  note?: string;
}

const ModalContent: FC<DNCNumbersToggleModalPropsI & ModalChildrenFnPropsI> = ({
  campaign,
  contact,
  handleClose,
  onSuccess,
}) => {
  const initialValues: FormValuesI = {
    phone: contact?.phone_v2?.do_not_call,
    mobile: contact?.mobile_v2?.do_not_call,
    note: "",
  };

  const handleDNCNumbers = async (data: FormValuesI) => {
    const API = glencocoClientAPI();

    const resp = await API.updatePropspectDNCnumbers(
      campaign?.id as string,
      contact?.id as string,
      {
        phones: [
          {
            phone: contact?.phone as string,
            dnc: data?.phone as boolean,
          },
          {
            phone: contact?.mobile as string,
            dnc: data?.mobile as boolean,
          },
        ],
        notes: data.note,
      }
    );

    if (resp.status !== 200) {
      toast.error("Failed to update prospect DNC numbers");
      return;
    }

    onSuccess?.();
    handleClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={FormValidationSchema}
      onSubmit={handleDNCNumbers}
    >
      {({ errors, touched }) => (
        <Form className="flex w-full flex-col gap-y-3">
          <EnhancedCheckboxButton
            label={contact?.phone as string}
            name="phone"
            className="w-full p-3"
            labelClassName="typography-body-4-bold"
          />

          <EnhancedCheckboxButton
            label={contact?.mobile as string}
            name="mobile"
            className="w-full p-3"
            labelClassName="typography-body-4-bold"
          />

          <EnhancedTextarea
            name="note"
            label="Add Note"
            placeholder="Tell us why you are marking this DNC"
            labelClassName="typography-body-4 text-black"
            inputClassName="border-[#ccc] bg-white min-h-[130px]"
            errors={errors?.note}
            touched={touched?.note}
          />

          <div className="flex items-center justify-end">
            <button
              type="button"
              className="btn-ae-text w-[140px]"
              onClick={handleClose}
            >
              Cancel
            </button>

            <button type="submit" className="btn-ae-default w-[140px]">
              Update
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const DNCNumbersToggleModal: FC<DNCNumbersToggleModalPropsI> = (
  props
) => (
  <Modal
    id={DNC_NUMBERS_TOGGLE_MODAL_ID}
    title="Do not call phone numbers"
    description="Select which phone numbers you want to mark as do not call."
  >
    {(modalProps) => <ModalContent {...props} {...modalProps} />}
  </Modal>
);
