import { FC, ReactNode } from "react";

import { ControlledDropdown } from "shared/ui";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

export interface FilterItemI {
  key: string;
  item: ReactNode;
}

export interface FilterDropdownSingleSelectPropsI extends PropsWithClassNameI {
  listClassName?: string;
  label?: ReactNode;
  defaultFilterSelectionLabel?: string;
  filtersListData?: FilterItemI[];
  selectedFilterKey?: string;
  onFilterChange?: (selectedData: FilterItemI) => void;
  disabled?: boolean;
}

const FilterDropdownSingleSelect: FC<FilterDropdownSingleSelectPropsI> = ({
  className,
  listClassName,
  label = "",
  defaultFilterSelectionLabel = "All",
  filtersListData,
  selectedFilterKey,
  onFilterChange = () => {},
  disabled = false,
}) => (
  <div className={clsxMerge("cursor-pointer", className)}>
    {/* prettier-ignore */}
    <ControlledDropdown className="dropdown-end">
      {() => (
        <>
          <label
            tabIndex={1}
            className="btn-ae btn-ae-neutral mr-1 flex-nowrap text-nowrap text-black/40"
          >
            <span className="ae-typography-detail1 mr-1">{label}:</span>

            <span className="ae-typography-detail1 text-black/80">
                {filtersListData?.find(f => f.key === selectedFilterKey)?.item || defaultFilterSelectionLabel}
              </span>
          </label>

          <div
            tabIndex={1}
            className="dropdown-content menu bg-base-100 min-w-[220px] rounded-lg p-3 shadow-lg"
          >
            <ul className={clsxMerge("list-none", listClassName)}>
              {filtersListData?.map((filter, i) => (
                <li key={i}>
                  <button
                    className={clsxMerge(
                      "label cursor-pointer px-1 py-2 my-1",
                      "brand-typography-body2",
                      {
                        "pointer-events-none opacity-50": disabled,
                        "bg-[#E8F3FF] border-2 border-[#4474E3]": filter.key === selectedFilterKey,
                        "bg-transparent hover:bg-[#DEDEDE]":
                          filter.key !== selectedFilterKey,
                      }
                    )}
                    onClick={() => onFilterChange(filter)}
                  >
                    {filter.item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </ControlledDropdown>
  </div>
);

export default FilterDropdownSingleSelect;
